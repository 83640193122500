export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Json: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** Input fields for accepting invitation. */
export type AcceptMemberInvitationInput = {
  token: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

/** Action result only returns errors */
export type ActionResult = {
  __typename?: 'ActionResult';
  userErrors?: Maybe<Array<UserError>>;
};

/** Input fields for adding a new tenant */
export type AddTenantInput = {
  commerceActivated?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isPersonal: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export enum AnalyticsIntervalType {
  /** last 7days, Last 30 days, month to date */
  Date = 'date',
  /** Last 24 hours */
  Hour = 'hour',
  /** Only for real time */
  Minute = 'minute',
  /** Last 12 months, year to date */
  Month = 'month'
}

export enum AnalyticsMetric {
  /** Avg time on story or page */
  AvgTime = 'avg_time',
  /** Total unique impressions */
  Impressions = 'impressions',
  /** Total pageviews */
  Pageviews = 'pageviews',
  /** Total unique plays, Sessions with more than 1 pageview */
  Plays = 'plays'
}

/** Period user selected */
export enum AnalyticsPeriodType {
  /** All */
  All = 'all',
  /** Day to Hour */
  Day = 'day',
  /** last 6 months */
  L6mo = 'l6mo',
  /** last 7 days */
  L7d = 'l7d',
  /** last 12 months */
  L12mo = 'l12mo',
  /** last 30 days */
  L30d = 'l30d',
  /** last 60 days */
  L60d = 'l60d',
  /** Month to Date */
  Month = 'month',
  /** Year to Date */
  Year = 'year'
}

/** The selected color channel */
export enum AnalyticsTargetType {
  /** Whole Account */
  Account = 'account',
  /** Story */
  Story = 'story',
  /** Custom link of a story */
  StoryLink = 'story_link'
}

/** API Key Object */
export type ApiKey = Node & {
  __typename?: 'ApiKey';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  lastUsedAt?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
  status: ApiKeyStatus;
};

export type ApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  edges?: Maybe<Array<Maybe<ApiKeyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ApiKey>;
};

/** API Key status */
export enum ApiKeyStatus {
  /** Active API Key */
  Active = 'ACTIVE',
  /** Revoked API Key */
  Revoked = 'REVOKED',
  /** Suspended API Key */
  Suspended = 'SUSPENDED'
}

export type AudioField = FormField & {
  __typename?: 'AudioField';
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  contactProperty?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  /** Audio Field */
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  type: FormFieldType;
};

/** Audio Upload Info */
export type AudioUpload = Node & {
  __typename?: 'AudioUpload';
  alternativeText?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isRecorded?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rawUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  transcodedUrl?: Maybe<Scalars['String']['output']>;
};

/** Object returning after user auth */
export type AuthResult = {
  __typename?: 'AuthResult';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  userErrors?: Maybe<Array<UserError>>;
  viewer?: Maybe<User>;
};

/** Background Type */
export enum BackgroundType {
  Gradient = 'gradient',
  Solid = 'solid'
}

export type BasicStoryPageStats = {
  __typename?: 'BasicStoryPageStats';
  choiceClicks: Scalars['Int']['output'];
  choicesStats?: Maybe<Array<ChoiceStats>>;
  ctaClicks: Scalars['Int']['output'];
  pageviews: Scalars['Int']['output'];
  productLinkClicks: Scalars['Int']['output'];
  productsStats?: Maybe<Array<ProductStats>>;
  textCollected: Scalars['Int']['output'];
  videoCollected: Scalars['Int']['output'];
  visitDuration: Scalars['Int']['output'];
};

export type BasicStoryStats = {
  __typename?: 'BasicStoryStats';
  choiceClicks: Scalars['Int']['output'];
  ctaClicks: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  pageviews: Scalars['Int']['output'];
  plays: Scalars['Int']['output'];
  productLinkClicks: Scalars['Int']['output'];
  storyId?: Maybe<Scalars['String']['output']>;
  textCollected: Scalars['Int']['output'];
  videoCollected: Scalars['Int']['output'];
  visitDuration: Scalars['Int']['output'];
};

/** Brand Setting Object */
export type BrandSetting = Node & {
  __typename?: 'BrandSetting';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  logoForDarkBgUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  symbolForDarkBgUrl?: Maybe<Scalars['String']['output']>;
  symbolUrl?: Maybe<Scalars['String']['output']>;
};

/** Object returning after creating or updating a brand_setting */
export type BrandSettingResult = {
  __typename?: 'BrandSettingResult';
  brandSetting?: Maybe<BrandSetting>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Channel object */
export type Channel = Node & {
  __typename?: 'Channel';
  databaseId: Scalars['UUID']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isPersonal: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  edges?: Maybe<Array<Maybe<ChannelEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Channel>;
};

export type ChannelMemberFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Object returning after creating or updating a channel */
export type ChannelResult = {
  __typename?: 'ChannelResult';
  channel?: Maybe<Channel>;
  userErrors?: Maybe<Array<UserError>>;
};

export type ChannelUnreadCount = {
  __typename?: 'ChannelUnreadCount';
  channelDatabaseId: Scalars['UUID']['output'];
  channelId: Scalars['ID']['output'];
  unreadCount: Scalars['Int']['output'];
};

/** Choice Action */
export type ChoiceAction = Node & {
  __typename?: 'ChoiceAction';
  databaseId: Scalars['UUID']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  nextPage?: Maybe<InitiationItem>;
  note?: Maybe<Scalars['String']['output']>;
  result?: Maybe<ChoiceActionResultType>;
  strValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ChoiceActionType>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type ChoiceActionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  nextPageId?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ChoiceActionResultType>;
  strValue?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ChoiceActionType>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export enum ChoiceActionResultType {
  /** Fix the quiz result */
  Fix = 'fix',
  /** Reset the quiz result, so the user can take another quiz */
  Reset = 'reset'
}

export enum ChoiceActionType {
  /** Jump to another story page */
  Jump = 'jump',
  /** Link to external URL */
  Link = 'link',
  /** Fix or Reset the result */
  Result = 'result',
  /** Add or Reduce the score */
  Score = 'score'
}

/** Choice Conditional */
export type ChoiceConditional = Node & {
  __typename?: 'ChoiceConditional';
  choice?: Maybe<PageChoice>;
  comparison: ChoiceConditionalComparisonType;
  databaseId: Scalars['UUID']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  question?: Maybe<PageQuestion>;
};

export enum ChoiceConditionalComparisonType {
  /** exactly same */
  Is = 'is'
}

export type ChoiceConditionalInput = {
  choiceId?: InputMaybe<Scalars['ID']['input']>;
  comparison?: InputMaybe<ChoiceConditionalComparisonType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  questionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Story Choice Letter Type */
export enum ChoiceLetterType {
  /** Alphabet */
  Alphabet = 'alphabet',
  /** Number */
  Number = 'number'
}

/** Conditional Logic Type. This will be applied only when the type is custom */
export type ChoiceLogic = Node & {
  __typename?: 'ChoiceLogic';
  actions?: Maybe<Array<Maybe<ChoiceAction>>>;
  conditionals?: Maybe<Array<Maybe<ChoiceConditional>>>;
  databaseId: Scalars['UUID']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  operator: LogicOperatorType;
};

export type ChoiceLogicMutationResult = {
  __typename?: 'ChoiceLogicMutationResult';
  choiceLogic?: Maybe<ChoiceLogic>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Input fields for page choice's middle interaction. */
export type ChoiceMiddleInteractionInput = {
  embedLink?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  question?: InputMaybe<Scalars['String']['input']>;
  type: MiddleInteractionType;
};

export type ChoiceStats = {
  __typename?: 'ChoiceStats';
  choiceId: Scalars['String']['output'];
  choiceText: Scalars['String']['output'];
  clicks: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
};

export type CollectContactResult = {
  __typename?: 'CollectContactResult';
  contact?: Maybe<Contact>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type CollectedVideoUploadResult = {
  __typename?: 'CollectedVideoUploadResult';
  userErrors?: Maybe<Array<Maybe<UserError>>>;
  videoUpload?: Maybe<VideoUpload>;
};

/** Color, based on Sharelo.Embeds.Color */
export type Color = {
  __typename?: 'Color';
  a: Scalars['Float']['output'];
  b: Scalars['Int']['output'];
  g: Scalars['Int']['output'];
  hex: Scalars['String']['output'];
  r: Scalars['Int']['output'];
};

/** Input fields for updating a widget setting. */
export type ColorInput = {
  a: Scalars['Float']['input'];
  b: Scalars['Int']['input'];
  g: Scalars['Int']['input'];
  hex: Scalars['String']['input'];
  r: Scalars['Int']['input'];
};

/** Company */
export type Company = Node & {
  __typename?: 'Company';
  contactCount: Scalars['Int']['output'];
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  thumbUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Company>;
};

export type CompanyFilter = {
  insertedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  insertedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Object returning after creating or updating a channel */
export type CompanyMutationResult = {
  __typename?: 'CompanyMutationResult';
  company?: Maybe<Company>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type CompleteUploadInput = {
  initiationItemId: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  tempId: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
  uploadParts: Array<InputMaybe<UploadPartInput>>;
  uploadPath: Scalars['String']['input'];
};

export type ConfirmShopifyInstallationInput = {
  code: Scalars['String']['input'];
  hmac: Scalars['String']['input'];
  message: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  shop: Scalars['String']['input'];
  timestamp: Scalars['String']['input'];
};

/** Result of confirming Shopify installation */
export type ConfirmShopifyInstallationResult = {
  __typename?: 'ConfirmShopifyInstallationResult';
  success?: Maybe<Scalars['Boolean']['output']>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Consent */
export type Consent = Node & {
  __typename?: 'Consent';
  currentVersion: ConsentVersion;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
};

/** Consent Agreement */
export type ConsentAgreement = Node & {
  __typename?: 'ConsentAgreement';
  agreedAt: Scalars['DateTime']['output'];
  consentVersion?: Maybe<ConsentVersion>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
};

export type ConsentAgreementInput = {
  agreement: Scalars['Boolean']['input'];
  consentId: Scalars['ID']['input'];
  consentVersionId: Scalars['ID']['input'];
};

export type ConsentAgreementsInput = {
  agreements?: InputMaybe<Array<ConsentAgreementInput>>;
};

/** Consent mutation result */
export type ConsentMutationResult = {
  __typename?: 'ConsentMutationResult';
  consent?: Maybe<Consent>;
  userErrors?: Maybe<Array<UserError>>;
};

/** Consent Version. This is a version of the consent. */
export type ConsentVersion = Node & {
  __typename?: 'ConsentVersion';
  consent: Consent;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

/** Contact */
export type Contact = Node & {
  __typename?: 'Contact';
  avatarThumbUrl?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Contact>;
};

export type ContactFilter = {
  insertedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  insertedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Contact Form Object */
export type ContactForm = Node & {
  __typename?: 'ContactForm';
  canSkip: Scalars['Boolean']['output'];
  consents: Array<Consent>;
  formFields: Array<FormField>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  prompt: Scalars['String']['output'];
  showAfterStoryItems: Array<InitiationItem>;
  showConsent: Scalars['Boolean']['output'];
};

export type ContactFormConsentInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isRequired: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

/** Object returning after creating or updating a channel */
export type ContactFormMutationResult = {
  __typename?: 'ContactFormMutationResult';
  contactForm?: Maybe<ContactForm>;
  userErrors?: Maybe<Array<UserError>>;
};

export type ContactInfoFormInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageTitle: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  storyContentId: Scalars['ID']['input'];
  storyId: Scalars['ID']['input'];
  storyPageId: Scalars['ID']['input'];
  storySessionId: Scalars['String']['input'];
  storySessionUserId: Scalars['String']['input'];
  storySid: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
  timestamp: Scalars['DateTime']['input'];
  url: Scalars['String']['input'];
};

/** Object returning after creating or updating a channel */
export type ContactMutationResult = {
  __typename?: 'ContactMutationResult';
  contact?: Maybe<Contact>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** contents */
export type Content = Node & {
  __typename?: 'Content';
  audio?: Maybe<AudioUpload>;
  body?: Maybe<Scalars['Json']['output']>;
  databaseId: Scalars['UUID']['output'];
  embedLink?: Maybe<Scalars['String']['output']>;
  file?: Maybe<FileUpload>;
  formFields?: Maybe<Array<Maybe<FormField>>>;
  groupType: ContentGroupType;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  narration?: Maybe<ContentNarration>;
  oembedResult?: Maybe<Scalars['Json']['output']>;
  plainDescription?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  type: ContentType;
  video?: Maybe<VideoUpload>;
  videoContain?: Maybe<Scalars['Boolean']['output']>;
};

/** Content access policy */
export enum ContentAccessPolicy {
  /** Content can be edited */
  CanEdit = 'CAN_EDIT',
  /** Content can be viewd */
  CanView = 'CAN_VIEW',
  /** Content is not allowed to access */
  NoAccess = 'NO_ACCESS'
}

/** Content Group Type */
export enum ContentGroupType {
  /** Scheduliing Calendar */
  Cal = 'cal',
  /** Codepen */
  Code = 'code',
  /** Design */
  Design = 'design',
  /** Document */
  Doc = 'doc',
  /** Form */
  Form = 'form',
  /** Unknown embeds */
  Other = 'other',
  /** Slide */
  Slide = 'slide',
  /** Spreadsheet */
  Spreadsheet = 'spreadsheet',
  /** Google Drive, or others */
  Storage = 'storage',
  /** User has not selected any type yet. */
  Unknown = 'unknown',
  /** Document */
  Video = 'video',
  /** Whiteboard */
  Whiteboard = 'whiteboard'
}

/** contents */
export type ContentNarration = Node & {
  __typename?: 'ContentNarration';
  chapters?: Maybe<Array<NarrationChapter>>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NarrationType>;
  video?: Maybe<VideoUpload>;
};

/** Object returning after creating or updating a content */
export type ContentNarrationResult = {
  __typename?: 'ContentNarrationResult';
  content?: Maybe<Content>;
  contentNarration?: Maybe<ContentNarration>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Object returning after creating or updating a content */
export type ContentResult = {
  __typename?: 'ContentResult';
  content?: Maybe<Content>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Content Type */
export enum ContentType {
  /** Sharelo's doc content */
  Doc = 'doc',
  /** Embed or PDF */
  Embed = 'embed',
  /** Sharelo Form, only story-type stories can have this. */
  Form = 'form',
  /** Contact Info */
  FormContactInfo = 'form_contact_info',
  /** Select from video, audio, or long-text field */
  FormSelectable = 'form_selectable',
  /** PDF based slide */
  Slide = 'slide',
  /** User has not selected any type yet. */
  Unknown = 'unknown',
  /** All screen video. Video Record, Video Upload */
  Video = 'video'
}

/** Input fields for creating an API Key */
export type CreateApiKeyInput = {
  name: Scalars['String']['input'];
};

/** Object returning after creating or updating a brand_setting */
export type CreateApiKeyResult = {
  __typename?: 'CreateApiKeyResult';
  apiKey?: Maybe<ApiKey>;
  rawApiKey?: Maybe<Scalars['String']['output']>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Input fields for create a channel. */
export type CreateChannelInput = {
  title: Scalars['String']['input'];
};

/** Input fields for creating collected video upload after upload on client side. */
export type CreateCollectedVideoUploadInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  duration: Scalars['Float']['input'];
  fileName: Scalars['String']['input'];
  generatedId: Scalars['String']['input'];
  isRecorded: Scalars['Boolean']['input'];
  size: Scalars['Float']['input'];
  tenantId: Scalars['ID']['input'];
  thumbSize: Scalars['Float']['input'];
  thumbnail: Scalars['Upload']['input'];
  uploadUrl: Scalars['String']['input'];
};

/** Attributes for creating a company */
export type CreateCompanyInput = {
  icon?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for creating a contact */
export type CreateConsentInput = {
  contactFormId: Scalars['ID']['input'];
  isRequired: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

/** Attributes for creating a contact */
export type CreateContactInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for creating a content. */
export type CreateContentInput = {
  embedLink?: InputMaybe<Scalars['String']['input']>;
  fileUploadId?: InputMaybe<Scalars['ID']['input']>;
  groupType: ContentGroupType;
  initiationItemId: Scalars['ID']['input'];
  oembedResult?: InputMaybe<Scalars['Json']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
  type: ContentType;
  videoUploadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Input fields for creating a content narration. */
export type CreateContentNarrationInput = {
  contentId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: NarrationType;
  videoUploadId: Scalars['ID']['input'];
};

export type CreateFileUploadInput = {
  file: Scalars['Upload']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  size: Scalars['Float']['input'];
};

export type CreateImageUploadInput = {
  file: Scalars['Upload']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
};

/** Input fields for create a thread. */
export type CreateInitiationInput = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<StoryType>;
};

/** Input fields for create many initiation invites. */
export type CreateInitiationInvitesInput = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  initiationId: Scalars['ID']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Input fields for create an initiation item. */
export type CreateInitiationItemInput = {
  contentGroupType: ContentGroupType;
  contentType: ContentType;
  initiationId: Scalars['ID']['input'];
  interactionType: InteractionType;
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type: InitiationItemType;
};

/** Input fields for updating user */
export type CreateMemberInvitationInput = {
  email: Scalars['String']['input'];
  roleName: Scalars['String']['input'];
};

/** Input fields for updating user */
export type CreateMemberInvitationsInput = {
  paramsList: Array<InputMaybe<CreateMemberInvitationInput>>;
};

/** Input fields for updating user */
export type CreateMemberWithInvitationInput = {
  displayName: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Input fields for creating a content narration. */
export type CreateNarrationChapterInput = {
  slidePageNumber?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  videoUploadId: Scalars['ID']['input'];
};

/** Input fields for creating video upload after upload on client side. Temporary, use for media recorder, too. */
export type CreateNarrationChapterVideoUploadInput = {
  contentId: Scalars['ID']['input'];
  duration: Scalars['Float']['input'];
  fileName: Scalars['String']['input'];
  generatedId: Scalars['String']['input'];
  isRecorded: Scalars['Boolean']['input'];
  mimetype: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  slidePageNumber: Scalars['Int']['input'];
  thumbSize: Scalars['Float']['input'];
  thumbnail: Scalars['Upload']['input'];
  uploadUrl: Scalars['String']['input'];
};

/** Input fields for creating video upload after upload on client side. Temporary, use for media recorder, too. */
export type CreateNarrationVideoUploadInput = {
  contentId: Scalars['ID']['input'];
  duration: Scalars['Float']['input'];
  fileName: Scalars['String']['input'];
  generatedId: Scalars['String']['input'];
  isRecorded: Scalars['Boolean']['input'];
  mimetype: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  thumbSize: Scalars['Float']['input'];
  thumbnail: Scalars['Upload']['input'];
  uploadUrl: Scalars['String']['input'];
};

/** Input fields for creating a page choice. */
export type CreatePageChoiceInput = {
  hasNext?: InputMaybe<Scalars['Boolean']['input']>;
  nextPageId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NextInteractionType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for creating a product */
export type CreateProductInput = {
  currencyCode: Scalars['String']['input'];
  descriptionHtml?: InputMaybe<Scalars['String']['input']>;
  imageUploadId: Scalars['String']['input'];
  onlineStoreUrl: Scalars['String']['input'];
  originalPrice?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Type of source */
  sourceType: ProductSourceType;
  title: Scalars['String']['input'];
};

/** Input fields for create a channel. */
export type CreateSaEventInput = {
  initiationItemId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input fields for verifying HMAC value on installing Sharelo's Shopify App. */
export type CreateShopifyAuthzRequestInput = {
  shop: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

/** Create a shoppable product from a product */
export type CreateShoppableProductFromProductInput = {
  ctaType: ShoppableProductCtaType;
  customCtaText?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Attributes for creating a shoppable product */
export type CreateShoppableProductInput = {
  ctaType: ShoppableProductCtaType;
  currencyCode: Scalars['String']['input'];
  customCtaText?: InputMaybe<Scalars['String']['input']>;
  imageUploadId?: InputMaybe<Scalars['String']['input']>;
  onlineStoreUrl: Scalars['String']['input'];
  originalPrice?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Type of source */
  sourceType: ProductSourceType;
  title: Scalars['String']['input'];
};

/** Input fields to add additional link to an existing story. */
export type CreateStoryLinkInput = {
  storyId: Scalars['ID']['input'];
};

/** Attributes for creating a story notification receiver */
export type CreateStoryNotificationReceiverInput = {
  memberId: Scalars['ID']['input'];
  storyId: Scalars['ID']['input'];
};

/** Attributes for creating a story notification setting */
export type CreateStoryNotificationSettingInput = {
  collectLeadEmail?: InputMaybe<Scalars['Boolean']['input']>;
  includeAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isMemberDefault: Scalars['Boolean']['input'];
  playEmail?: InputMaybe<Scalars['Boolean']['input']>;
  reachEndEmail?: InputMaybe<Scalars['Boolean']['input']>;
  responseEmail?: InputMaybe<Scalars['Boolean']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for creating a story response */
export type CreateStoryResponseInput = {
  answerType?: InputMaybe<StoryResponseAnswerType>;
  endScreenId?: InputMaybe<Scalars['ID']['input']>;
  pageChoiceId?: InputMaybe<Scalars['ID']['input']>;
  pageChoiceText?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageQuestionId?: InputMaybe<Scalars['ID']['input']>;
  pageQuestionText?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  storyContentId?: InputMaybe<Scalars['ID']['input']>;
  storyPageId?: InputMaybe<Scalars['ID']['input']>;
  storySessionId: Scalars['ID']['input'];
  stringValue?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  type: StoryResponseType;
  value?: InputMaybe<Scalars['Float']['input']>;
  videoUploadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for creating a story timeline */
export type CreateStoryTimelineInput = {
  anonymousId?: InputMaybe<Scalars['String']['input']>;
  clickCount?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  lastEventAt?: InputMaybe<Scalars['DateTime']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
  storySessionId?: InputMaybe<Scalars['String']['input']>;
  storySessionUserId?: InputMaybe<Scalars['String']['input']>;
  storySid?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for creating stripe checkout session */
export type CreateStripeCheckoutSessionInput = {
  stripePriceId: Scalars['String']['input'];
};

export type CreateStripeCheckoutSessionResult = {
  __typename?: 'CreateStripeCheckoutSessionResult';
  checkoutSession?: Maybe<StripeCheckoutSession>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type CreateStripeCustomerPortalSessionResult = {
  __typename?: 'CreateStripeCustomerPortalSessionResult';
  session?: Maybe<StripeCustomerPortalSession>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Input fields for create a thread. */
export type CreateThreadInput = {
  title: Scalars['String']['input'];
};

/** Input fields for creating video upload after upload on client side. Temporary, use for media recorder, too. */
export type CreateVideoUploadInput = {
  duration: Scalars['Float']['input'];
  fileName: Scalars['String']['input'];
  generatedId: Scalars['String']['input'];
  initiationItemId?: InputMaybe<Scalars['ID']['input']>;
  isRecorded: Scalars['Boolean']['input'];
  mimetype: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  thumbSize: Scalars['Float']['input'];
  thumbnail: Scalars['Upload']['input'];
  uploadUrl: Scalars['String']['input'];
};

/** Input fields for deleting a channel. */
export type DeleteChannelInput = {
  id: Scalars['ID']['input'];
};

/** Input fields for deleting an initiation item. */
export type DeleteInitiationItemInput = {
  id: Scalars['ID']['input'];
};

/** Attributes for removing a member from a story notification receivers */
export type DeleteStoryNotificationReceiverInput = {
  id: Scalars['ID']['input'];
};

/** Input fields for duplicating initiation. */
export type DuplicateInitiationInput = {
  id: Scalars['ID']['input'];
};

/** Input fields for duplicating initiation item. */
export type DuplicateInitiationItemInput = {
  id: Scalars['ID']['input'];
};

export type DuplicateInitiationResult = {
  __typename?: 'DuplicateInitiationResult';
  initiation?: Maybe<Initiation>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Return after checking email availability */
export type EmailAvailabilityResult = {
  __typename?: 'EmailAvailabilityResult';
  isAvailable: Scalars['Boolean']['output'];
};

export type EmailField = FormField & {
  __typename?: 'EmailField';
  contactProperty?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  /** Email Field */
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  type: FormFieldType;
};

/** Embed */
export type Embed = Node & {
  __typename?: 'Embed';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  linkUrl: Scalars['String']['output'];
  oembedResult?: Maybe<Scalars['Json']['output']>;
  reason?: Maybe<EmbedFailureReason>;
  status: EmbedStatus;
};

/** Embed Failure Reason */
export enum EmbedFailureReason {
  /** API Error */
  ApiError = 'api_error',
  /** Others */
  Others = 'others',
  /** Wrong URL */
  WrongUrl = 'wrong_url'
}

/** Embed Status */
export enum EmbedStatus {
  /** Completed Embed */
  Completed = 'completed',
  /** Failed Embed */
  Failed = 'failed',
  /** Pending Embed */
  Pending = 'pending'
}

/** End Screen Setting */
export type EndScreenSetting = Node & {
  __typename?: 'EndScreenSetting';
  bgGradientAngle: Scalars['Int']['output'];
  bgGradientType: GradientType;
  bgImageUpload?: Maybe<ImageUpload>;
  bgType: BackgroundType;
  blurEffect: Scalars['Int']['output'];
  colors: Array<Color>;
  content?: Maybe<Scalars['String']['output']>;
  ctaBgColor?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  ctaTextColor?: Maybe<Scalars['String']['output']>;
  ctaUrl?: Maybe<Scalars['String']['output']>;
  databaseId: Scalars['UUID']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  showCta: Scalars['Boolean']['output'];
  storyPage?: Maybe<InitiationItem>;
  textColor: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

/** Object returning after creating or updating an end_screen_setting */
export type EndScreenSettingResult = {
  __typename?: 'EndScreenSettingResult';
  endScreenSetting?: Maybe<EndScreenSetting>;
  userErrors?: Maybe<Array<UserError>>;
};

export type FetchS3PresignedUrlInput = {
  fileName: Scalars['String']['input'];
  generatedId: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
};

/** Mutation result after fetch presigned url */
export type FetchS3PresignedUrlResult = {
  __typename?: 'FetchS3PresignedUrlResult';
  signedUrl: Scalars['String']['output'];
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** File Upload Info */
export type FileUpload = Node & {
  __typename?: 'FileUpload';
  alternativeText?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pageCount?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
};

export type FileUploadConnection = {
  __typename?: 'FileUploadConnection';
  edges?: Maybe<Array<Maybe<FileUploadEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FileUploadEdge = {
  __typename?: 'FileUploadEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<FileUpload>;
};

export type FileUploadFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Return when a file is uploaded */
export type FileUploadResult = {
  __typename?: 'FileUploadResult';
  fileUpload?: Maybe<FileUpload>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Form Field */
export type FormField = {
  contactProperty?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type FormFieldInput = {
  /** Allow upload for video and audio fields */
  allowUpload?: InputMaybe<Scalars['Boolean']['input']>;
  contactProperty?: InputMaybe<Scalars['String']['input']>;
  /** Default country code for phone field */
  defaultCountryCode?: InputMaybe<Scalars['String']['input']>;
  i18nKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isCustomField: Scalars['Boolean']['input'];
  isHidden: Scalars['Boolean']['input'];
  isRequired: Scalars['Boolean']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  /** Max length for short text field */
  max?: InputMaybe<Scalars['Int']['input']>;
  /** Min length for short text field */
  min?: InputMaybe<Scalars['Int']['input']>;
  type: FormFieldType;
};

export enum FormFieldType {
  /** Audio Field */
  AudioField = 'audio_field',
  /** Email Field */
  EmailField = 'email_field',
  /** File Upload Field */
  FileUploadField = 'file_upload_field',
  /** Long Text Field */
  LongTextField = 'long_text_field',
  /** Phone Field */
  PhoneField = 'phone_field',
  /** Selectable Field */
  SelectableField = 'selectable_field',
  /** Short Text Field */
  ShortTextField = 'short_text_field',
  /** Video Field */
  VideoField = 'video_field'
}

/** Input fields for verifying the Instagram OAuth callback. */
export type GetInstagramAccessTokenInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

/** Input fields for getting the OAuth login URL for Instagram. */
export type GetInstagramOauthUrlInput = {
  afterCallbackUrl: Scalars['String']['input'];
};

/** Input fields for verifying TikTok oauth callback. */
export type GetTiktokAccessTokenInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

/** Input fields for getting oauth login url for TikTok. */
export type GetTiktokOauthUrlInput = {
  afterCallbackUrl: Scalars['String']['input'];
};

/** Input fields for get tus upload url. */
export type GetTusUploadEndpointInput = {
  initiationItemId?: InputMaybe<Scalars['ID']['input']>;
  length: Scalars['String']['input'];
};

/** Background Gradient Type */
export enum GradientType {
  Linear = 'linear',
  Radial = 'radial'
}

export type IdentifiedContactInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  storyId: Scalars['ID']['input'];
  storySessionId: Scalars['String']['input'];
  storySessionUserId: Scalars['String']['input'];
  storySid: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
  timestamp: Scalars['DateTime']['input'];
  url: Scalars['String']['input'];
};

/** Image Upload Info */
export type ImageUpload = Node & {
  __typename?: 'ImageUpload';
  alternativeText?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
};

export type ImageUploadConnection = {
  __typename?: 'ImageUploadConnection';
  edges?: Maybe<Array<Maybe<ImageUploadEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ImageUploadEdge = {
  __typename?: 'ImageUploadEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ImageUpload>;
};

/** Return when a image is uploaded */
export type ImageUploadResult = {
  __typename?: 'ImageUploadResult';
  imageUpload?: Maybe<ImageUpload>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Initial Playback Mode of the widget */
export enum InitialPlaybackMode {
  /** Autoplay Muted */
  AutoplayMuted = 'autoplay_muted',
  /** Autoplay Unmuted */
  AutoplayUnmuted = 'autoplay_unmuted',
  /** Click to Play */
  ClickToPlay = 'click_to_play'
}

/** Initiation object */
export type Initiation = Node & {
  __typename?: 'Initiation';
  brandSetting: BrandSetting;
  canUseVideoPreview?: Maybe<Scalars['Boolean']['output']>;
  channel?: Maybe<Channel>;
  contactForm: ContactForm;
  databaseId: Scalars['UUID']['output'];
  endScreenSetting: EndScreenSetting;
  firstVideo?: Maybe<VideoUpload>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  initiationItems?: Maybe<Array<InitiationItem>>;
  insertedAt: Scalars['DateTime']['output'];
  owner?: Maybe<User>;
  ownerMember?: Maybe<Member>;
  previewVideoUrl?: Maybe<Scalars['String']['output']>;
  sessionCount?: Maybe<Scalars['Int']['output']>;
  setting: InitiationSetting;
  sid: Scalars['String']['output'];
  storyLink?: Maybe<StoryLink>;
  tenantId: Scalars['String']['output'];
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: StoryType;
  unreadCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  widgetSetting: WidgetSetting;
};

export type InitiationConnection = {
  __typename?: 'InitiationConnection';
  edges?: Maybe<Array<Maybe<InitiationEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InitiationEdge = {
  __typename?: 'InitiationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Initiation>;
};

export type InitiationFilter = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** InitiationInvite */
export type InitiationInvite = Node & {
  __typename?: 'InitiationInvite';
  email?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  invitedAt: Scalars['DateTime']['output'];
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  member?: Maybe<Member>;
  role: InitiationMemberRole;
};

export type InitiationInviteConnection = {
  __typename?: 'InitiationInviteConnection';
  edges?: Maybe<Array<Maybe<InitiationInviteEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InitiationInviteEdge = {
  __typename?: 'InitiationInviteEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<InitiationInvite>;
};

/** Object returning after creating or updating an initiation_setting */
export type InitiationInviteResult = {
  __typename?: 'InitiationInviteResult';
  initiationInvite?: Maybe<InitiationInvite>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Object returning after creating or updating an initiation_setting */
export type InitiationInvitesResult = {
  __typename?: 'InitiationInvitesResult';
  initiationInvites?: Maybe<Array<Maybe<InitiationInvite>>>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Initiation Item object */
export type InitiationItem = Node & {
  __typename?: 'InitiationItem';
  bgGradientAngle?: Maybe<Scalars['Int']['output']>;
  bgGradientType: GradientType;
  bgType: BackgroundType;
  blurEffect?: Maybe<Scalars['Int']['output']>;
  collectContact: Scalars['Boolean']['output'];
  colors?: Maybe<Array<Color>>;
  content?: Maybe<Content>;
  databaseId: Scalars['UUID']['output'];
  headline?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  interaction: Interaction;
  interactionType: InteractionType;
  interactions: Array<Interaction>;
  order: Scalars['Int']['output'];
  question?: Maybe<PageQuestion>;
  showCompanyName: Scalars['Boolean']['output'];
  showCompanySymbol: Scalars['Boolean']['output'];
  showContact: Scalars['Boolean']['output'];
  showContactAvatar: Scalars['Boolean']['output'];
  showContactJobTitle: Scalars['Boolean']['output'];
  showContactName: Scalars['Boolean']['output'];
  teleprompterFontColor?: Maybe<Scalars['String']['output']>;
  teleprompterFontSize?: Maybe<Scalars['Int']['output']>;
  teleprompterScript?: Maybe<Scalars['String']['output']>;
  teleprompterSpeed?: Maybe<Scalars['Int']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: InitiationItemType;
  useTeleprompter: Scalars['Boolean']['output'];
  useTextFallback: Scalars['Boolean']['output'];
};

export type InitiationItemConnection = {
  __typename?: 'InitiationItemConnection';
  edges?: Maybe<Array<Maybe<InitiationItemEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InitiationItemEdge = {
  __typename?: 'InitiationItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<InitiationItem>;
};

/** Object returning after creating or updating a channel */
export type InitiationItemResult = {
  __typename?: 'InitiationItemResult';
  initiationItem?: Maybe<InitiationItem>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Initiation item type */
export enum InitiationItemType {
  /** Sharelo's doc content */
  Doc = 'doc',
  /** Embed or PDF */
  Embed = 'embed',
  /** End Screen */
  EndScreen = 'end_screen',
  /** Form-type story. Only story-type story can have this. */
  Form = 'form',
  /** Form Part */
  FormPart = 'form_part',
  /** PDF based slide */
  Slide = 'slide',
  /** User has not selected any type yet. */
  Unknown = 'unknown',
  /** All screen video. Video Record, Video Upload */
  Video = 'video'
}

/** Check current user liked the initiation or not */
export type InitiationLike = {
  __typename?: 'InitiationLike';
  id: Scalars['ID']['output'];
  initiationId: Scalars['ID']['output'];
};

/** Initiation Member Role */
export enum InitiationMemberRole {
  /** Editor can edit initiation pages and comment */
  Editor = 'EDITOR',
  /** Owner can edit initiation settings and manage members */
  Owner = 'OWNER',
  /** Viewer can comment */
  Viewer = 'VIEWER'
}

export type InitiationMetaResult = {
  __typename?: 'InitiationMetaResult';
  id?: Maybe<Scalars['String']['output']>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Object returning after creating or updating a initiation */
export type InitiationMutationResult = {
  __typename?: 'InitiationMutationResult';
  initiation?: Maybe<Initiation>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Return result of fetching an initiation */
export type InitiationResult = Initiation | InvalidInviteToken | InviteRequired | LinkNoAccess | RequireInvitation | ShareDisabled | UnknownError;

/** Initiation Setting */
export type InitiationSetting = Node & {
  __typename?: 'InitiationSetting';
  canChangeMediaSpeed: Scalars['Boolean']['output'];
  canShare: Scalars['Boolean']['output'];
  choiceLetterType: ChoiceLetterType;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isInteractive: Scalars['Boolean']['output'];
  linkPolicy: ContentAccessPolicy;
  locale: StoryLocale;
  orientation: PlayerOrientationType;
  showBadge: Scalars['Boolean']['output'];
  showChoiceLetters: Scalars['Boolean']['output'];
  showPages: Scalars['Boolean']['output'];
  tenantMemberPolicy: ContentAccessPolicy;
  widgetLive: Scalars['Boolean']['output'];
  widgetType: WidgetType;
};

/** Object returning after creating or updating an initiation_setting */
export type InitiationSettingResult = {
  __typename?: 'InitiationSettingResult';
  initiationSetting?: Maybe<InitiationSetting>;
  userErrors?: Maybe<Array<UserError>>;
};

/** Result of verifying Instagram oauth callback */
export type InstagramAccessTokenResult = {
  __typename?: 'InstagramAccessTokenResult';
  tenantInstagramInfo?: Maybe<TenantInstagramInfo>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Result of getting oauth login url for Instagram. */
export type InstagramOauthUrlResult = {
  __typename?: 'InstagramOauthUrlResult';
  url?: Maybe<Scalars['String']['output']>;
};

/** Interaction */
export type Interaction = {
  id: Scalars['ID']['output'];
  storyItem: InitiationItem;
  type: InteractionType;
};

/** Interaction Type */
export enum InteractionType {
  /** Call to Action */
  Cta = 'cta',
  /** Multiple Choice */
  MultipleChoice = 'multiple_choice',
  /** Net Promoter Score */
  Nps = 'nps',
  /** An interaction for Shoppable Video */
  Shoppable = 'shoppable'
}

export type InvalidInviteToken = {
  __typename?: 'InvalidInviteToken';
  message?: Maybe<Scalars['String']['output']>;
};

export type InviteRequired = {
  __typename?: 'InviteRequired';
  message?: Maybe<Scalars['String']['output']>;
};

/** Invoice */
export type Invoice = {
  __typename?: 'Invoice';
  currency?: Maybe<Scalars['String']['output']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  invoicePdf?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  periodEnd?: Maybe<Scalars['DateTime']['output']>;
  periodStart?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Input fields for create an initiation like. */
export type LikeInitiationInput = {
  initiationId: Scalars['ID']['input'];
};

export type LinkNoAccess = {
  __typename?: 'LinkNoAccess';
  message?: Maybe<Scalars['String']['output']>;
};

/** Available locales */
export enum Locale {
  /** English */
  En = 'en',
  /** Japanese */
  Ja = 'ja'
}

export enum LogicOperatorType {
  /** AND operator */
  And = 'and',
  /** OR operator */
  Or = 'or'
}

/** Input fields for logging inas a user with email. */
export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LongTextField = FormField & {
  __typename?: 'LongTextField';
  contactProperty?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  /** Long Text Field */
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  type: FormFieldType;
};

/** Object returning after looking up user with email */
export type LookupUserResult = {
  __typename?: 'LookupUserResult';
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
};

export type MainGraphFilter = {
  metric: AnalyticsMetric;
  period: AnalyticsPeriodType;
  target: AnalyticsTargetType;
  targetValue?: InputMaybe<Scalars['String']['input']>;
  /** Temporary, just guess timezone on client side */
  timezone: Scalars['String']['input'];
};

export type MainGraphResult = {
  __typename?: 'MainGraphResult';
  interval: AnalyticsIntervalType;
  labels?: Maybe<Array<Scalars['String']['output']>>;
  plot?: Maybe<Array<Scalars['Float']['output']>>;
  presentIndex?: Maybe<Scalars['Int']['output']>;
};

export type MarkAllStoryTimelinesAsReadResult = {
  __typename?: 'MarkAllStoryTimelinesAsReadResult';
  completed: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Member */
export type Member = Node & {
  __typename?: 'Member';
  /** can be either member avatar or user avatar */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Display name can be either member display name or user display name */
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  role: Scalars['String']['output'];
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  edges?: Maybe<Array<Maybe<MemberEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MemberEdge = {
  __typename?: 'MemberEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Member>;
};

export type MemberFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
};

/** MemberInvitation */
export type MemberInvitation = Node & {
  __typename?: 'MemberInvitation';
  email?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  invitedAt?: Maybe<Scalars['DateTime']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type MemberInvitationConnection = {
  __typename?: 'MemberInvitationConnection';
  edges?: Maybe<Array<Maybe<MemberInvitationEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MemberInvitationEdge = {
  __typename?: 'MemberInvitationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<MemberInvitation>;
};

/** Return when a member is updated */
export type MemberInvitationResult = {
  __typename?: 'MemberInvitationResult';
  memberInvitation?: Maybe<MemberInvitation>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Return when members are updated */
export type MemberInvitationsResult = {
  __typename?: 'MemberInvitationsResult';
  memberInvitations?: Maybe<Array<Maybe<MemberInvitation>>>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Return when a member is updated */
export type MemberResult = {
  __typename?: 'MemberResult';
  member?: Maybe<Member>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Member Status */
export enum MemberStatus {
  /** Active member */
  Active = 'ACTIVE',
  /** Inactive member */
  Inactive = 'INACTIVE'
}

/** Middle Interaction */
export type MiddleInteraction = Node & {
  __typename?: 'MiddleInteraction';
  embed?: Maybe<Embed>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  question?: Maybe<Scalars['String']['output']>;
  type: MiddleInteractionType;
};

/** Middle Interaction Types */
export enum MiddleInteractionType {
  /** Show Calendar */
  Cal = 'cal',
  /** File Upload */
  File = 'file',
  /** Image Upload */
  Image = 'image',
  /** This is like a placeholder, no actual middle interaction. */
  Jump = 'jump',
  /** Text Response */
  Text = 'text',
  /** Video Response */
  Video = 'video'
}

export type MoveInitiationChannelInput = {
  channelId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

/** Multiple Choice Interaction */
export type MultipleChoiceInteraction = Interaction & Node & {
  __typename?: 'MultipleChoiceInteraction';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  multipleChoiceInteractionId: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  question: PageQuestion;
  storyItem: InitiationItem;
  type: InteractionType;
};

/** Narration Chapters */
export type NarrationChapter = Node & {
  __typename?: 'NarrationChapter';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  slidePageNumber?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<VideoUpload>;
};

/** Object returning after creating or updating a content */
export type NarrationChapterResult = {
  __typename?: 'NarrationChapterResult';
  content?: Maybe<Content>;
  narrationChapter?: Maybe<NarrationChapter>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Narration Types */
export enum NarrationType {
  /** Show narration and content side by side */
  Parallel = 'parallel',
  /** Show small rounded video on the content */
  Rounded = 'rounded',
  /** Show video and content separately */
  Wipe = 'wipe'
}

/** Next interaction types */
export enum NextInteractionType {
  /** Conditonal Logic */
  Custom = 'Custom',
  /** End the story */
  EndScreen = 'end_screen',
  /** Open external link */
  Link = 'link',
  /** Go to a page */
  Page = 'page'
}

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

/** Notification Setting Object */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  emailNews: Scalars['Boolean']['output'];
};

/** Object returning after creating or updating an initiation_setting */
export type NotificationSettingResult = {
  __typename?: 'NotificationSettingResult';
  notificationSetting?: Maybe<NotificationSetting>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Notion Oauth Info */
export type NotionOauthInfo = Node & {
  __typename?: 'NotionOauthInfo';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  notionWorkspaceName?: Maybe<Scalars['String']['output']>;
};

/** Notion Oauth Info */
export type NotionPage = {
  __typename?: 'NotionPage';
  archived?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['Json']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  createdTime?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  lastEditedTime?: Maybe<Scalars['String']['output']>;
  notionId?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['Json']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Input fields for onboarding users with adding basic info. */
export type OnboardBasicsInput = {
  displayName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Input fields for onboarding users with adding tenant info. */
export type OnboardTenantInput = {
  commerceActivated: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** The selected color channel */
export enum OnboardingStatus {
  /** Before inputing basics */
  Basics = 'BASICS',
  /** Completed onboarding */
  Completed = 'COMPLETED',
  /** Before registering tenant info */
  Tenant = 'TENANT',
  /** Before verifying email */
  VerifyEmail = 'VERIFY_EMAIL'
}

/** Choice setting */
export type PageChoice = Node & {
  __typename?: 'PageChoice';
  customLogics?: Maybe<Array<Maybe<ChoiceLogic>>>;
  databaseId: Scalars['UUID']['output'];
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  middleInteraction: MiddleInteraction;
  /** When the type is custom, this will be the fallback action. */
  nextPage?: Maybe<InitiationItem>;
  order: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NextInteractionType>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Object returning after creating or updating an initiation_setting */
export type PageChoiceResult = {
  __typename?: 'PageChoiceResult';
  pageChoice?: Maybe<PageChoice>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Question setting */
export type PageQuestion = Node & {
  __typename?: 'PageQuestion';
  choices: Array<PageChoice>;
  databaseId: Scalars['UUID']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  showTitle: Scalars['Boolean']['output'];
  storyPage?: Maybe<InitiationItem>;
  timing: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  titleColor: Scalars['String']['output'];
};

/** Object returning after creating or updating an initiation_setting */
export type PageQuestionResult = {
  __typename?: 'PageQuestionResult';
  pageQuestion?: Maybe<PageQuestion>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type PhoneField = FormField & {
  __typename?: 'PhoneField';
  contactProperty?: Maybe<Scalars['String']['output']>;
  defaultCountryCode?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  /** Phone Field */
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  type: FormFieldType;
};

/** Plan */
export type Plan = {
  __typename?: 'Plan';
  id?: Maybe<Scalars['String']['output']>;
  includedImpressions?: Maybe<Scalars['Int']['output']>;
  initialMembers?: Maybe<Scalars['Int']['output']>;
  initialPlays?: Maybe<Scalars['Int']['output']>;
  initialStorage?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isTrial?: Maybe<Scalars['Boolean']['output']>;
  isYearly?: Maybe<Scalars['Boolean']['output']>;
  maxLifetimePlays?: Maybe<Scalars['Int']['output']>;
  members?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameI18nKey?: Maybe<Scalars['String']['output']>;
  plays?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  storage?: Maybe<Scalars['Int']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
};

/** Features only for paid plans */
export enum PlanFeature {
  /** Users can customize the branding of the player and landing page. */
  CustomBranding = 'custom_branding',
  /** Set passcode to stories to open */
  PasscodeLock = 'passcode_lock',
  /** Remove Sharelo branding from video and landing page. */
  RemoveBadge = 'remove_badge',
  /** Use video as the widget thumbnail */
  VideoThumbWidgets = 'video_thumb_widgets',
  /** VIP support includes faster support, video chat support, faster feature request addition, and more */
  VipSupport = 'vip_support'
}

/** Subscription */
export type PlanSubscription = Node & {
  __typename?: 'PlanSubscription';
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currentPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  discountApplied?: Maybe<Scalars['Boolean']['output']>;
  discountDurationInMonths?: Maybe<Scalars['Int']['output']>;
  discountEnd?: Maybe<Scalars['DateTime']['output']>;
  discountPercentOff?: Maybe<Scalars['Float']['output']>;
  discountStart?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  plan: Plan;
  quantity?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<SubscriptionStatus>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
  yearly?: Maybe<Scalars['Boolean']['output']>;
};

/** Story Player Orientation Type */
export enum PlayerOrientationType {
  /** Horizontal */
  Horizontal = 'horizontal',
  /** Vertical */
  Vertical = 'vertical'
}

export type PrepareUploadInput = {
  extension: Scalars['String']['input'];
};

/** Product */
export type Product = Node & {
  __typename?: 'Product';
  currencyCode: Scalars['String']['output'];
  databaseId: Scalars['UUID']['output'];
  defaultVariant?: Maybe<ProductVariant>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionHtml?: Maybe<Scalars['String']['output']>;
  featuredImage?: Maybe<ProductImage>;
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  images?: Maybe<Array<ProductImage>>;
  onlineStoreUrl: Scalars['String']['output'];
  options?: Maybe<Array<ProductOption>>;
  shop?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<ProductTag>>;
  title: Scalars['String']['output'];
  type: ProductType;
  variants?: Maybe<Array<ProductVariant>>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Product>;
};

/** Product Filter */
export type ProductFilter = {
  /** Search with title */
  search?: InputMaybe<Scalars['String']['input']>;
  /** List of tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Product Image */
export type ProductImage = Node & {
  __typename?: 'ProductImage';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  imageUpload?: Maybe<ImageUpload>;
  position?: Maybe<Scalars['Int']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<ProductSourceType>;
  src?: Maybe<Scalars['String']['output']>;
};

/** Object returning after creating or updating a product */
export type ProductMutationResult = {
  __typename?: 'ProductMutationResult';
  product?: Maybe<Product>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Product Option */
export type ProductOption = {
  __typename?: 'ProductOption';
  id: Scalars['String']['output'];
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Product Source Type */
export enum ProductSourceType {
  /** Added directly on Sharelo */
  Sharelo = 'sharelo',
  /** Imported from connected Shopify store */
  Shopify = 'shopify'
}

export type ProductStats = {
  __typename?: 'ProductStats';
  clicks: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  productId: Scalars['String']['output'];
};

/** Product Tag */
export type ProductTag = Node & {
  __typename?: 'ProductTag';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** Product Type */
export type ProductType = Node & {
  __typename?: 'ProductType';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  sourceId?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<ProductSourceType>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Product Variant */
export type ProductVariant = {
  __typename?: 'ProductVariant';
  barcode?: Maybe<Scalars['String']['output']>;
  grams?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  options?: Maybe<Array<ProductOption>>;
  originalPrice?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
};

/** Input fields for updating an initiation item. */
export type ReorderInitiationItemInput = {
  id: Scalars['ID']['input'];
  newOrder: Scalars['Int']['input'];
};

/** Input fields for updating an initiation item. */
export type ReorderInitiationItemsInput = {
  reorderList: Array<InputMaybe<ReorderInitiationItemInput>>;
};

/** Input fields for reorder one item. */
export type ReorderPageChoiceInput = {
  id: Scalars['ID']['input'];
  newOrder: Scalars['Int']['input'];
};

/** Input fields for reordering choices. */
export type ReorderPageChoicesInput = {
  reorderList: Array<InputMaybe<ReorderPageChoiceInput>>;
};

/** Input fields for reodering a shoppable product. */
export type ReorderShoppableProductInput = {
  id: Scalars['ID']['input'];
  newPosition: Scalars['Int']['input'];
};

/** Input fields for reordering shoppable products. */
export type ReorderShoppableProductsInput = {
  reorderList: Array<InputMaybe<ReorderShoppableProductInput>>;
};

export type RequireInvitation = {
  __typename?: 'RequireInvitation';
  message?: Maybe<Scalars['String']['output']>;
};

/** Input fields for resetting email to verify. */
export type ResetEmailToVerifyInput = {
  newEmail: Scalars['String']['input'];
  oldEmail: Scalars['String']['input'];
};

/** Input fields for reset password. */
export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Accept member invitation and ask the user to login */
  acceptMemberInvitation?: Maybe<AuthResult>;
  /** Add members */
  addChannelMembers: ChannelResult;
  /** Add a Choice Action. */
  addChoiceAction?: Maybe<ChoiceLogicMutationResult>;
  /** Add a Choice Conditonal. */
  addChoiceConditional?: Maybe<ChoiceLogicMutationResult>;
  addTenant?: Maybe<TenantResult>;
  /** Complete upload */
  completeUpload?: Maybe<InitiationItemResult>;
  /** Confirm Shopify app installation */
  confirmShopifyInstallation?: Maybe<ConfirmShopifyInstallationResult>;
  /** Create an API Key. Only Owner can create one. */
  createApiKey: CreateApiKeyResult;
  /** Create a channel */
  createChannel: ChannelResult;
  /** Create a Choice Logic. */
  createChoiceLogic?: Maybe<ChoiceLogicMutationResult>;
  createCollectedVideoUpload?: Maybe<CollectedVideoUploadResult>;
  /** Create a company */
  createCompany?: Maybe<CompanyMutationResult>;
  /** Create a consent */
  createConsent?: Maybe<ConsentMutationResult>;
  /** Create a contact */
  createContact?: Maybe<ContactMutationResult>;
  /** Create a content */
  createContent?: Maybe<ContentResult>;
  /** Create a content narration */
  createContentNarration?: Maybe<ContentNarrationResult>;
  createFileUpload?: Maybe<FileUploadResult>;
  createImageUpload?: Maybe<ImageUploadResult>;
  /** Create an initiation */
  createInitiation?: Maybe<InitiationMutationResult>;
  /** Create invites */
  createInitiationInvites?: Maybe<InitiationInvitesResult>;
  /** Create an initiation item */
  createInitiationItem?: Maybe<InitiationItemResult>;
  /** Owner can invite members */
  createMemberInvitation?: Maybe<MemberInvitationResult>;
  /** Owner can invite members */
  createMemberInvitations?: Maybe<MemberInvitationsResult>;
  /** Create member and user altogether(only if a user doesn't exist) with invitation token. */
  createMemberWithInvitation?: Maybe<ViewerResult>;
  /** Create a content narration */
  createNarrationChapter?: Maybe<NarrationChapterResult>;
  /** Create a video upload object and create content narration after uploading on client side. */
  createNarrationChapterVideoUpload?: Maybe<VideoUploadResult>;
  /** Create a video upload object and create content narration after uploading on client side. */
  createNarrationVideoUpload?: Maybe<VideoUploadResult>;
  /** Create a page choice */
  createPageChoice?: Maybe<PageChoiceResult>;
  /** Create a product */
  createProduct?: Maybe<ProductMutationResult>;
  /** Create an event */
  createSaEvent?: Maybe<SaEventMutationResult>;
  /** Create Shopify authz request */
  createShopifyAuthzRequest?: Maybe<ActionResult>;
  /** Create a shoppable product and product */
  createShoppableProduct?: Maybe<ShoppableProductMutationResult>;
  /** Create a shoppable product from a product */
  createShoppableProductFromProduct?: Maybe<ShoppableProductMutationResult>;
  /** Create a story link */
  createStoryLink?: Maybe<StoryLinkMutationResult>;
  /** Create a story notification receiver */
  createStoryNotificationReceiver: StoryNotificationReceiverResult;
  /** Create a story notification setting */
  createStoryNotificationSetting?: Maybe<StoryNotificationSettingResult>;
  /** Create a story response */
  createStoryResponse?: Maybe<ActionResult>;
  /** Create a story timeline */
  createStoryTimeline?: Maybe<StoryTimelineResult>;
  /** Create checkout session. */
  createStripeCheckoutSession?: Maybe<CreateStripeCheckoutSessionResult>;
  /** Create customer portal session. */
  createStripeCustomerPortalSession?: Maybe<CreateStripeCustomerPortalSessionResult>;
  /** Create a thread */
  createThread?: Maybe<ThreadResult>;
  /** Create a video upload object and create content after uploading on client side. */
  createVideoUpload?: Maybe<VideoUploadResult>;
  /** Get one-time endpoint to upload video to cloudflare stream endpoint. */
  createVideoUploadRequest?: Maybe<VideoUploadRequestResult>;
  /** Delete an API Key */
  deleteApiKey: ActionResult;
  /** Delete a channel */
  deleteChannel: ActionResult;
  /** Delete a Choice Action. */
  deleteChoiceAction?: Maybe<ChoiceLogicMutationResult>;
  /** Delete a Choice Conditonal. */
  deleteChoiceConditional?: Maybe<ChoiceLogicMutationResult>;
  /** Delete a Choice Logic. */
  deleteChoiceLogic?: Maybe<ActionResult>;
  /** Delete a company */
  deleteCompany?: Maybe<ActionResult>;
  /** Delete a consent. This operation is logical deletion. Set the deleted_at field to the current time. */
  deleteConsent?: Maybe<ActionResult>;
  /** Delete a contact */
  deleteContact?: Maybe<ActionResult>;
  deleteContactFormConsent: ActionResult;
  /** Delete a content narration */
  deleteContentNarration?: Maybe<ActionResult>;
  /** Delete a file upload. */
  deleteFileUpload?: Maybe<ActionResult>;
  /** Delete a image upload. */
  deleteImageUpload?: Maybe<ActionResult>;
  /** Delete an initiation */
  deleteInitiation?: Maybe<ActionResult>;
  /** Delete an initiation item */
  deleteInitiationItem?: Maybe<ActionResult>;
  /** Delete a content narration */
  deleteNarrationChapter?: Maybe<ActionResult>;
  /** Delete a page choice */
  deletePageChoice?: Maybe<ActionResult>;
  /** Delete a product */
  deleteProduct?: Maybe<ActionResult>;
  /** Delete a product */
  deleteShoppableProduct?: Maybe<ActionResult>;
  /** Delete a story link */
  deleteStoryLink?: Maybe<ActionResult>;
  /** Delete a story notification receiver */
  deleteStoryNotificationReceiver: ActionResult;
  /** Delete a story timeline */
  deleteStoryTimeline?: Maybe<ActionResult>;
  /** Delete a video upload. */
  deleteVideoUpload?: Maybe<ActionResult>;
  /** Duplicate an initiation */
  duplicateInitiation?: Maybe<DuplicateInitiationResult>;
  /** Duplicate an initiation item */
  duplicateInitiationItem?: Maybe<ActionResult>;
  /** Fetch presigned url for s3 direct upload */
  fetchS3PresignedUrl?: Maybe<FetchS3PresignedUrlResult>;
  identifiedContactWithQueryString?: Maybe<CollectContactResult>;
  /** Inactivate a member. Only owner is allowed to perform this action. */
  inactivateMember?: Maybe<ActionResult>;
  /** Verify Instagram OAuth callback */
  instagramAccessToken?: Maybe<InstagramAccessTokenResult>;
  /** Refresh Instagram access token */
  instagramRefreshAccessToken?: Maybe<InstagramAccessTokenResult>;
  /** Like an initiation */
  likeInitiation?: Maybe<ActionResult>;
  /** Log in user */
  login?: Maybe<AuthResult>;
  /** Lookup user by email */
  lookupMeWithEmail?: Maybe<LookupUserResult>;
  markAllStoryTimelinesAsRead: MarkAllStoryTimelinesAsReadResult;
  markStoryTimelineAsArchived?: Maybe<ActionResult>;
  markStoryTimelineAsRead?: Maybe<ActionResult>;
  markStoryTimelineAsSpam?: Maybe<ActionResult>;
  markStoryTimelineAsUnarchived?: Maybe<ActionResult>;
  markStoryTimelineAsUnread?: Maybe<ActionResult>;
  markStoryTimelineAsUnspam?: Maybe<ActionResult>;
  /** Move initiation to another channel */
  moveInitiationChannel?: Maybe<InitiationMutationResult>;
  /** Onboarding Step: Basics */
  onboardBasics?: Maybe<ViewerResult>;
  /** Onboarding Step: Tenant */
  onboardTenant?: Maybe<ViewerResult>;
  /** Initialize multi part upload */
  prepareUpload?: Maybe<UploadState>;
  reachedStoryEnd?: Maybe<ActionResult>;
  /** Inactivate a member. Only owner is allowed to perform this action. */
  reactivateMember?: Maybe<MemberResult>;
  removeBgImageUploadFromEndScreenSetting?: Maybe<EndScreenSettingResult>;
  /** Remove members */
  removeChannelMembers: ChannelResult;
  removeFileUploadFromContent?: Maybe<ContentResult>;
  /** Delete an initiation invite */
  removeInitiationInvite?: Maybe<ActionResult>;
  removeVideoUploadFromContent?: Maybe<ContentResult>;
  /** Reorder an initiation item */
  reorderInitiationItem?: Maybe<ActionResult>;
  /** Reorder initiation items */
  reorderInitiationItems?: Maybe<ActionResult>;
  /** Reorder all choices */
  reorderPageChoices?: Maybe<ActionResult>;
  /** Reorder a shoppable product */
  reorderShoppableProduct?: Maybe<ActionResult>;
  /** Reorder shoppable products */
  reorderShoppableProducts?: Maybe<ActionResult>;
  /** Request to create gif with icon */
  requestCreateGif?: Maybe<VideoUploadResult>;
  /** Resend verify email link */
  resendConfirmationToken?: Maybe<ActionResult>;
  /** Owner can revoke the member invitation not accepted. */
  resendInitiationInvite?: Maybe<InitiationInviteResult>;
  /** Owner can revoke the member invitation not accepted. */
  resendMemberInvitation?: Maybe<MemberInvitationResult>;
  /** Reset email and send verify email link */
  resetEmailToVerify?: Maybe<AuthResult>;
  /** Update password with reset_password_token and new password */
  resetPassword?: Maybe<ActionResult>;
  /** Owner can revoke the member invitation not accepted. */
  revokeMemberInvitation?: Maybe<ActionResult>;
  /** Create a product with Shopify product */
  saveProductWithShopifyProduct?: Maybe<ProductMutationResult>;
  /** Create a product with Shopify URL */
  saveProductWithShopifyUrl?: Maybe<ProductMutationResult>;
  /** Send reset password link */
  sendResetPasswordLink?: Maybe<ActionResult>;
  setBgImageUploadToEndScreenSetting?: Maybe<EndScreenSettingResult>;
  setFileUploadToContent?: Maybe<ContentResult>;
  setVideoUploadToContent?: Maybe<ContentResult>;
  /** Signup a user */
  signup?: Maybe<AuthResult>;
  submitContactInfoForm?: Maybe<CollectContactResult>;
  switchTenant?: Maybe<ViewerResult>;
  /** Verify TikTok oauth callback */
  tiktokAccessToken?: Maybe<TiktokAccessTokenResult>;
  /** Refresh TikTok access token */
  tiktokRefreshAccessToken?: Maybe<TiktokAccessTokenResult>;
  /** Unlike an initiation */
  unlikeInitiation?: Maybe<ActionResult>;
  /** Update an API Key. Only Owner can update one. */
  updateApiKey: UpdateApiKeyResult;
  /** Update brand setting */
  updateBrandSetting?: Maybe<BrandSettingResult>;
  /** Update a channel */
  updateChannel: ChannelResult;
  /** Update a Choice Action. */
  updateChoiceAction?: Maybe<ChoiceLogicMutationResult>;
  /** Update a Choice Conditonal. */
  updateChoiceConditional?: Maybe<ChoiceLogicMutationResult>;
  /** Update a Choice Logic. */
  updateChoiceLogic?: Maybe<ChoiceLogicMutationResult>;
  /** Update a company */
  updateCompany?: Maybe<CompanyMutationResult>;
  /**
   * Update a cosent. This operation checks if the current version of the consnet has any agreements,
   * if so, create a new version, if not, just update the current version.
   */
  updateConsent?: Maybe<ConsentMutationResult>;
  /** Update a contact */
  updateContact?: Maybe<ContactMutationResult>;
  /** Update a contact form */
  updateContactForm: ContactFormMutationResult;
  /** Update contact form fields */
  updateContactFormFields: ContactFormMutationResult;
  /** Update a content */
  updateContent?: Maybe<ContentResult>;
  /** Update a content narration */
  updateContentNarration?: Maybe<ContentNarrationResult>;
  /** Update an end screen setting */
  updateEndScreenSetting?: Maybe<EndScreenSettingResult>;
  /** Update a file upload object currently only allow rename. */
  updateFileUpload?: Maybe<FileUploadResult>;
  /** Update a short text field */
  updateFormField?: Maybe<ContentResult>;
  /** Update a image upload object currently only allow rename. */
  updateImageUpload?: Maybe<ImageUploadResult>;
  /** Update an initiation */
  updateInitiation?: Maybe<InitiationMutationResult>;
  /** Switch between intereactive or one-way story */
  updateInitiationInteractiveness?: Maybe<InitiationSettingResult>;
  /** Owner can update the member invitation. */
  updateInitiationInvite?: Maybe<InitiationInviteResult>;
  /** Update an initiation item */
  updateInitiationItem?: Maybe<InitiationItemResult>;
  /** Update an initiation setting */
  updateInitiationSetting?: Maybe<InitiationSettingResult>;
  /** Switch widget live and off */
  updateInitiationWidgetLive?: Maybe<InitiationSettingResult>;
  /** Primary owner and owner can update the member role. */
  updateMemberAsAdmin?: Maybe<MemberResult>;
  /** Owner can update the member invitation. */
  updateMemberInvitation?: Maybe<MemberInvitationResult>;
  /** Current user update the member profile on current workspace. */
  updateMemberProfile?: Maybe<MemberResult>;
  /** Update a content narration */
  updateNarrationChapter?: Maybe<NarrationChapterResult>;
  /** Update notification setting */
  updateNotificationSetting?: Maybe<NotificationSettingResult>;
  /** Update a page choice */
  updatePageChoice?: Maybe<PageChoiceResult>;
  /** Update an question */
  updatePageQuestion?: Maybe<PageQuestionResult>;
  /** Update logged in user's password */
  updatePassword?: Maybe<ViewerResult>;
  /** Update a product */
  updateProduct?: Maybe<ProductMutationResult>;
  /** Update a shoppable product */
  updateShoppableProduct?: Maybe<ShoppableProductMutationResult>;
  /** Update a shoppable product and product */
  updateShoppableProductAndProduct?: Maybe<ShoppableProductMutationResult>;
  /** Update a story link */
  updateStoryLink?: Maybe<StoryLinkMutationResult>;
  /** Update a story notification setting */
  updateStoryNotificationSetting?: Maybe<StoryNotificationSettingResult>;
  /** Update a story timeline */
  updateStoryTimeline?: Maybe<StoryTimelineResult>;
  updateTenant?: Maybe<TenantResult>;
  /** Update a thread */
  updateThread?: Maybe<ThreadResult>;
  updateUser?: Maybe<ViewerResult>;
  /** Update a video upload object. */
  updateVideoUpload?: Maybe<VideoUploadResult>;
  /** Update a widget setting */
  updateWidgetSetting?: Maybe<WidgetSettingResult>;
  uploadAvatar?: Maybe<ViewerResult>;
  /** Upload brand logo */
  uploadBrandLogo?: Maybe<BrandSettingResult>;
  /** Upload brand logo for dark bg */
  uploadBrandLogoForDarkBg?: Maybe<BrandSettingResult>;
  /** Upload brand symbol */
  uploadBrandSymbol?: Maybe<BrandSettingResult>;
  /** Upload brand symbol for dark bg */
  uploadBrandSymbolForDarkBg?: Maybe<BrandSettingResult>;
  /** Send chunk of upload */
  uploadChunk?: Maybe<UploadState>;
  /** Upload company icon */
  uploadCompanyIcon?: Maybe<CompanyMutationResult>;
  /** Update an initiation thumbnail */
  uploadInitiationThumbnail?: Maybe<InitiationMutationResult>;
  /** Current user can update the member avatar on current workspace. */
  uploadMemberAvatar?: Maybe<MemberResult>;
  uploadTenantIcon?: Maybe<TenantResult>;
  /** Upload a video */
  uploadVideo?: Maybe<VideoUploadResult>;
  /** verify email */
  verifyEmail?: Maybe<AuthResult>;
  /** Verify Shopify app installation request */
  verifyShopifyInstallationRequest?: Maybe<VerifyShopifyInstallationRequestResult>;
};


export type RootMutationTypeAcceptMemberInvitationArgs = {
  input: AcceptMemberInvitationInput;
};


export type RootMutationTypeAddChannelMembersArgs = {
  memberIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type RootMutationTypeAddChoiceActionArgs = {
  choiceLogicId: Scalars['ID']['input'];
};


export type RootMutationTypeAddChoiceConditionalArgs = {
  choiceLogicId: Scalars['ID']['input'];
};


export type RootMutationTypeAddTenantArgs = {
  input: AddTenantInput;
};


export type RootMutationTypeCompleteUploadArgs = {
  input: CompleteUploadInput;
};


export type RootMutationTypeConfirmShopifyInstallationArgs = {
  input: ConfirmShopifyInstallationInput;
};


export type RootMutationTypeCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type RootMutationTypeCreateChannelArgs = {
  input: CreateChannelInput;
};


export type RootMutationTypeCreateChoiceLogicArgs = {
  choiceId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateCollectedVideoUploadArgs = {
  input: CreateCollectedVideoUploadInput;
};


export type RootMutationTypeCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type RootMutationTypeCreateConsentArgs = {
  contactFormId: Scalars['ID']['input'];
  input: CreateConsentInput;
};


export type RootMutationTypeCreateContactArgs = {
  input: CreateContactInput;
};


export type RootMutationTypeCreateContentArgs = {
  input: CreateContentInput;
};


export type RootMutationTypeCreateContentNarrationArgs = {
  input: CreateContentNarrationInput;
};


export type RootMutationTypeCreateFileUploadArgs = {
  input: CreateFileUploadInput;
};


export type RootMutationTypeCreateImageUploadArgs = {
  input: CreateImageUploadInput;
};


export type RootMutationTypeCreateInitiationArgs = {
  input: CreateInitiationInput;
};


export type RootMutationTypeCreateInitiationInvitesArgs = {
  input: CreateInitiationInvitesInput;
};


export type RootMutationTypeCreateInitiationItemArgs = {
  input: CreateInitiationItemInput;
};


export type RootMutationTypeCreateMemberInvitationArgs = {
  input: CreateMemberInvitationInput;
};


export type RootMutationTypeCreateMemberInvitationsArgs = {
  input: CreateMemberInvitationsInput;
};


export type RootMutationTypeCreateMemberWithInvitationArgs = {
  input: CreateMemberWithInvitationInput;
};


export type RootMutationTypeCreateNarrationChapterArgs = {
  contentId: Scalars['ID']['input'];
  input: CreateNarrationChapterInput;
};


export type RootMutationTypeCreateNarrationChapterVideoUploadArgs = {
  input: CreateNarrationChapterVideoUploadInput;
};


export type RootMutationTypeCreateNarrationVideoUploadArgs = {
  input: CreateNarrationVideoUploadInput;
  narrationType: NarrationType;
};


export type RootMutationTypeCreatePageChoiceArgs = {
  input: CreatePageChoiceInput;
};


export type RootMutationTypeCreateProductArgs = {
  input: CreateProductInput;
};


export type RootMutationTypeCreateSaEventArgs = {
  input: CreateSaEventInput;
};


export type RootMutationTypeCreateShopifyAuthzRequestArgs = {
  input: CreateShopifyAuthzRequestInput;
};


export type RootMutationTypeCreateShoppableProductArgs = {
  input: CreateShoppableProductInput;
  shoppableInteractionId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateShoppableProductFromProductArgs = {
  input: CreateShoppableProductFromProductInput;
  shoppableInteractionId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateStoryLinkArgs = {
  input: CreateStoryLinkInput;
};


export type RootMutationTypeCreateStoryNotificationReceiverArgs = {
  input: CreateStoryNotificationReceiverInput;
};


export type RootMutationTypeCreateStoryNotificationSettingArgs = {
  input: CreateStoryNotificationSettingInput;
};


export type RootMutationTypeCreateStoryResponseArgs = {
  input: CreateStoryResponseInput;
};


export type RootMutationTypeCreateStoryTimelineArgs = {
  input: CreateStoryTimelineInput;
};


export type RootMutationTypeCreateStripeCheckoutSessionArgs = {
  input: CreateStripeCheckoutSessionInput;
};


export type RootMutationTypeCreateThreadArgs = {
  input: CreateThreadInput;
};


export type RootMutationTypeCreateVideoUploadArgs = {
  input: CreateVideoUploadInput;
};


export type RootMutationTypeCreateVideoUploadRequestArgs = {
  input: GetTusUploadEndpointInput;
};


export type RootMutationTypeDeleteApiKeyArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteChannelArgs = {
  input: DeleteChannelInput;
};


export type RootMutationTypeDeleteChoiceActionArgs = {
  choiceLogicId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteChoiceConditionalArgs = {
  choiceLogicId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteChoiceLogicArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteConsentArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteContactArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteContactFormConsentArgs = {
  consentId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteContentNarrationArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteFileUploadArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteImageUploadArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteInitiationArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteInitiationItemArgs = {
  input: DeleteInitiationItemInput;
};


export type RootMutationTypeDeleteNarrationChapterArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeletePageChoiceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteProductArgs = {
  input: CreateProductInput;
};


export type RootMutationTypeDeleteShoppableProductArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteStoryLinkArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteStoryNotificationReceiverArgs = {
  input: DeleteStoryNotificationReceiverInput;
};


export type RootMutationTypeDeleteStoryTimelineArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteVideoUploadArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDuplicateInitiationArgs = {
  input: DuplicateInitiationInput;
};


export type RootMutationTypeDuplicateInitiationItemArgs = {
  input: DuplicateInitiationItemInput;
};


export type RootMutationTypeFetchS3PresignedUrlArgs = {
  input: FetchS3PresignedUrlInput;
};


export type RootMutationTypeIdentifiedContactWithQueryStringArgs = {
  input: IdentifiedContactInput;
};


export type RootMutationTypeInactivateMemberArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeInstagramAccessTokenArgs = {
  input: GetInstagramAccessTokenInput;
};


export type RootMutationTypeLikeInitiationArgs = {
  input: LikeInitiationInput;
};


export type RootMutationTypeLoginArgs = {
  input: LoginInput;
};


export type RootMutationTypeLookupMeWithEmailArgs = {
  email: Scalars['String']['input'];
};


export type RootMutationTypeMarkAllStoryTimelinesAsReadArgs = {
  storyId: Scalars['ID']['input'];
};


export type RootMutationTypeMarkStoryTimelineAsArchivedArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeMarkStoryTimelineAsReadArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeMarkStoryTimelineAsSpamArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeMarkStoryTimelineAsUnarchivedArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeMarkStoryTimelineAsUnreadArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeMarkStoryTimelineAsUnspamArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeMoveInitiationChannelArgs = {
  input: MoveInitiationChannelInput;
};


export type RootMutationTypeOnboardBasicsArgs = {
  input: OnboardBasicsInput;
};


export type RootMutationTypeOnboardTenantArgs = {
  input: OnboardTenantInput;
};


export type RootMutationTypePrepareUploadArgs = {
  input: PrepareUploadInput;
};


export type RootMutationTypeReachedStoryEndArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeReactivateMemberArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveBgImageUploadFromEndScreenSettingArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveChannelMembersArgs = {
  memberIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type RootMutationTypeRemoveFileUploadFromContentArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveInitiationInviteArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveVideoUploadFromContentArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeReorderInitiationItemArgs = {
  input: ReorderInitiationItemInput;
};


export type RootMutationTypeReorderInitiationItemsArgs = {
  input: ReorderInitiationItemsInput;
};


export type RootMutationTypeReorderPageChoicesArgs = {
  input: ReorderPageChoicesInput;
};


export type RootMutationTypeReorderShoppableProductArgs = {
  input: ReorderShoppableProductInput;
};


export type RootMutationTypeReorderShoppableProductsArgs = {
  input: ReorderShoppableProductsInput;
};


export type RootMutationTypeRequestCreateGifArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeResendConfirmationTokenArgs = {
  email: Scalars['String']['input'];
};


export type RootMutationTypeResendInitiationInviteArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeResendMemberInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeResetEmailToVerifyArgs = {
  input: ResetEmailToVerifyInput;
};


export type RootMutationTypeResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type RootMutationTypeRevokeMemberInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeSaveProductWithShopifyProductArgs = {
  input: SaveProductWithShopifyProductInput;
};


export type RootMutationTypeSaveProductWithShopifyUrlArgs = {
  input: SaveProductWithShopifyUrlInput;
};


export type RootMutationTypeSendResetPasswordLinkArgs = {
  email: Scalars['String']['input'];
};


export type RootMutationTypeSetBgImageUploadToEndScreenSettingArgs = {
  id: Scalars['ID']['input'];
  input: SetBgImageUploadToEndScreenSettingInput;
};


export type RootMutationTypeSetFileUploadToContentArgs = {
  input: SetFileUploadToContentInput;
};


export type RootMutationTypeSetVideoUploadToContentArgs = {
  input: SetVideoUploadToContentInput;
};


export type RootMutationTypeSignupArgs = {
  input: SignupInput;
};


export type RootMutationTypeSubmitContactInfoFormArgs = {
  consentsInput: ConsentAgreementsInput;
  input: ContactInfoFormInput;
};


export type RootMutationTypeSwitchTenantArgs = {
  tenantId: Scalars['ID']['input'];
};


export type RootMutationTypeTiktokAccessTokenArgs = {
  input: GetTiktokAccessTokenInput;
};


export type RootMutationTypeUnlikeInitiationArgs = {
  input: UnlikeInitiationInput;
};


export type RootMutationTypeUpdateApiKeyArgs = {
  id: Scalars['ID']['input'];
  input: UpdateApiKeyInput;
};


export type RootMutationTypeUpdateBrandSettingArgs = {
  input: UpdateBrandSettingInput;
};


export type RootMutationTypeUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type RootMutationTypeUpdateChoiceActionArgs = {
  choiceLogicId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: UpdateChoiceActionInput;
};


export type RootMutationTypeUpdateChoiceConditionalArgs = {
  choiceLogicId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: UpdateChoiceConditionalInput;
};


export type RootMutationTypeUpdateChoiceLogicArgs = {
  id: Scalars['ID']['input'];
  input: UpdateChoiceLogicInput;
};


export type RootMutationTypeUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCompanyInput;
};


export type RootMutationTypeUpdateConsentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateConsentInput;
  versionId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateContactArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
};


export type RootMutationTypeUpdateContactFormArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContactFormInput;
};


export type RootMutationTypeUpdateContactFormFieldsArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContactFormFieldsInput;
};


export type RootMutationTypeUpdateContentArgs = {
  input: UpdateContentInput;
};


export type RootMutationTypeUpdateContentNarrationArgs = {
  input: UpdateContentNarrationInput;
};


export type RootMutationTypeUpdateEndScreenSettingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateEndScreenSettingInput;
};


export type RootMutationTypeUpdateFileUploadArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFileUploadInput;
};


export type RootMutationTypeUpdateFormFieldArgs = {
  contentId: Scalars['ID']['input'];
  input: FormFieldInput;
};


export type RootMutationTypeUpdateImageUploadArgs = {
  input: UpdateImageUploadInput;
};


export type RootMutationTypeUpdateInitiationArgs = {
  input: UpdateInitiationInput;
};


export type RootMutationTypeUpdateInitiationInteractivenessArgs = {
  input: UpdateInitiationInteractivenessInput;
};


export type RootMutationTypeUpdateInitiationInviteArgs = {
  input: UpdateInitiationInviteInput;
};


export type RootMutationTypeUpdateInitiationItemArgs = {
  input: UpdateInitiationItemInput;
};


export type RootMutationTypeUpdateInitiationSettingArgs = {
  input: UpdateInitiationSettingInput;
};


export type RootMutationTypeUpdateInitiationWidgetLiveArgs = {
  input: UpdateInitiationWidgetLiveInput;
};


export type RootMutationTypeUpdateMemberAsAdminArgs = {
  input: UpdateMemberAsAdminInput;
};


export type RootMutationTypeUpdateMemberInvitationArgs = {
  input: UpdateMemberInvitationInput;
};


export type RootMutationTypeUpdateMemberProfileArgs = {
  input: UpdateMemberInput;
};


export type RootMutationTypeUpdateNarrationChapterArgs = {
  input: UpdateNarrationChapterInput;
};


export type RootMutationTypeUpdateNotificationSettingArgs = {
  input: UpdateNotificationSettingInput;
};


export type RootMutationTypeUpdatePageChoiceArgs = {
  input: UpdatePageChoiceInput;
};


export type RootMutationTypeUpdatePageQuestionArgs = {
  input: UpdatePageQuestionInput;
};


export type RootMutationTypeUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type RootMutationTypeUpdateProductArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProductInput;
};


export type RootMutationTypeUpdateShoppableProductArgs = {
  id: Scalars['ID']['input'];
  input: UpdateShoppableProductInput;
};


export type RootMutationTypeUpdateShoppableProductAndProductArgs = {
  id: Scalars['ID']['input'];
  input: UpdateShoppableProductAndProductInput;
};


export type RootMutationTypeUpdateStoryLinkArgs = {
  input: UpdateStoryLinkInput;
};


export type RootMutationTypeUpdateStoryNotificationSettingArgs = {
  input: UpdateStoryNotificationSettingInput;
};


export type RootMutationTypeUpdateStoryTimelineArgs = {
  id: Scalars['ID']['input'];
  input: UpdateStoryTimelineInput;
};


export type RootMutationTypeUpdateTenantArgs = {
  input: UpdateTenantInput;
};


export type RootMutationTypeUpdateThreadArgs = {
  input: UpdateThreadInput;
};


export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput;
};


export type RootMutationTypeUpdateVideoUploadArgs = {
  input: UpdateVideoUploadInput;
};


export type RootMutationTypeUpdateWidgetSettingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWidgetSettingInput;
};


export type RootMutationTypeUploadAvatarArgs = {
  input: UploadAvatarInput;
};


export type RootMutationTypeUploadBrandLogoArgs = {
  input: UploadBrandLogoInput;
};


export type RootMutationTypeUploadBrandLogoForDarkBgArgs = {
  input: UploadBrandLogoForDarkBgInput;
};


export type RootMutationTypeUploadBrandSymbolArgs = {
  input: UploadBrandSymbolInput;
};


export type RootMutationTypeUploadBrandSymbolForDarkBgArgs = {
  input: UploadBrandSymbolForDarkBgInput;
};


export type RootMutationTypeUploadChunkArgs = {
  input: UploadChunkInput;
};


export type RootMutationTypeUploadCompanyIconArgs = {
  companyId: Scalars['ID']['input'];
  input: UploadCompanyIconInput;
};


export type RootMutationTypeUploadInitiationThumbnailArgs = {
  input: UploadInitiationThumbnailInput;
};


export type RootMutationTypeUploadMemberAvatarArgs = {
  input: UploadMemberAvatarInput;
};


export type RootMutationTypeUploadTenantIconArgs = {
  input: UploadTenantIconInput;
};


export type RootMutationTypeUploadVideoArgs = {
  input: UploadVideoInput;
};


export type RootMutationTypeVerifyEmailArgs = {
  input: VerifyEmailInput;
};


export type RootMutationTypeVerifyShopifyInstallationRequestArgs = {
  input: VerifyShopifyInstallationRequestInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Get active plan */
  activePlans?: Maybe<Array<Maybe<Plan>>>;
  /** Get active tenant */
  activeTenant?: Maybe<Tenant>;
  /** Get all the api_keys */
  apiKeys?: Maybe<ApiKeyConnection>;
  /** Get brand_setting */
  brandSetting?: Maybe<BrandSetting>;
  /** Get one channel */
  channel?: Maybe<Channel>;
  /** Get all channel members, optionally filtering */
  channelMembers: MemberConnection;
  /** Get all channels, optionally filtering */
  channels: ChannelConnection;
  /** Check email availability for invite */
  checkEmailForInitiationInvite?: Maybe<EmailAvailabilityResult>;
  choiceLogics?: Maybe<Array<Maybe<ChoiceLogic>>>;
  /** Get all companies */
  companies?: Maybe<CompanyConnection>;
  /** Get companies for select */
  companiesForSelect?: Maybe<CompanyConnection>;
  /** Get a company by id */
  company?: Maybe<Company>;
  /** Get a contact by id */
  contact?: Maybe<Contact>;
  /** Get all contacts */
  contacts?: Maybe<ContactConnection>;
  /** Get contacts for select */
  contactsForSelect?: Maybe<ContactConnection>;
  /** Get current subscription */
  currentSubscription?: Maybe<PlanSubscription>;
  /** Get user's default story notification setting */
  defaultStoryNotificationSetting?: Maybe<StoryNotificationSetting>;
  fetchChannelUnreadCount: ChannelUnreadCount;
  fetchChannelsUnreadCount: Array<ChannelUnreadCount>;
  /** Get one contact_form */
  fetchContactFormByStoryId: ContactForm;
  fetchMyChannelUnreadCount: ChannelUnreadCount;
  /** Fetch page data */
  fetchNotionPage?: Maybe<NotionPage>;
  fetchStoriesUnreadCount: Array<StoryUnreadCount>;
  /** Get all the choices of a question */
  fetchStoryUnreadCount: StoryUnreadCount;
  /** Get all the files, optionally filtering */
  fileUploads?: Maybe<FileUploadConnection>;
  /** Get all the images, optionally filtering */
  imageUploads?: Maybe<ImageUploadConnection>;
  /** Get one initiatin */
  initiation?: Maybe<InitiationResult>;
  /** Get all invites of an initiation, optionally filtering */
  initiationInvitableMembers?: Maybe<MemberConnection>;
  /** Get all invites of an initiation, optionally filtering */
  initiationInvites?: Maybe<InitiationInviteConnection>;
  /** Get all the users, optionally filtering */
  initiationItem?: Maybe<InitiationItem>;
  /** Get all the initiation items */
  initiationItemFeed?: Maybe<InitiationItemConnection>;
  /** Get all the users, optionally filtering */
  initiationItems?: Maybe<Array<Maybe<InitiationItem>>>;
  /** Check email availability for invite */
  initiationLike?: Maybe<InitiationLike>;
  /** Fetch story meta */
  initiationMeta?: Maybe<InitiationMetaResult>;
  /** Get all the initiations, optionally filtering */
  initiations?: Maybe<InitiationConnection>;
  /** Get Instagram oauth login url */
  instagramOauthUrl?: Maybe<InstagramOauthUrlResult>;
  /** Get all the users, optionally filtering */
  interactions: Array<Interaction>;
  /** Get main graph data */
  mainGraph?: Maybe<MainGraphResult>;
  /** Get all active member_invitations */
  memberInvitations?: Maybe<MemberInvitationConnection>;
  /** Get all the members, optionally filtering */
  members?: Maybe<MemberConnection>;
  /** Get current_user's personal channel */
  myChannel?: Maybe<Channel>;
  /** Get all the initiations, optionally filtering */
  myInitiations?: Maybe<InitiationConnection>;
  node?: Maybe<Node>;
  /** Fetch info of the tenant */
  notionOauthInfo?: Maybe<NotionOauthInfo>;
  /** Get all the choices of a question */
  pageQuestionChoices?: Maybe<Array<Maybe<PageChoice>>>;
  /** Get all the choices of a question */
  pageQuestions?: Maybe<Array<PageQuestion>>;
  /** Get up to 10 paid and upcoming invoices */
  paidInvoices?: Maybe<Array<Maybe<Invoice>>>;
  /** Get one product */
  product?: Maybe<Product>;
  /** Get all products, optionally filtering */
  products?: Maybe<ProductConnection>;
  /** Get shared initiatin */
  sharedInitiation?: Maybe<SharedInitiationResult>;
  /** Get Shopify oauth info */
  shopifyOauthInfo?: Maybe<ShopifyOauthInfoResult>;
  /** Get all shopify_products, optionally filtering */
  shopifyProducts?: Maybe<ShopifyProductConnection>;
  /** Get a shoppable product */
  shoppableProduct?: Maybe<ShoppableProduct>;
  storiesStats?: Maybe<StoriesStatsResult>;
  /** Get the first video of an initiation */
  storyFirstVideo?: Maybe<VideoUpload>;
  /** Get one story_link */
  storyLink?: Maybe<StoryLink>;
  /** Get story link feed, optionally filtering */
  storyLinks?: Maybe<StoryLinkConnection>;
  /** Get story notification receivers */
  storyNotificationReceivers: Array<StoryNotificationReceiver>;
  /** Get a story notification setting */
  storyNotificationSetting?: Maybe<StoryNotificationSetting>;
  storyPageStats?: Maybe<StoryPageStatsResult>;
  storyPagesStats?: Maybe<StoryPagesStatsResult>;
  /** Get a story timeline by id */
  storyTimeline?: Maybe<StoryTimeline>;
  /** Get all the story timelines, optionally filtering */
  storyTimelines?: Maybe<StoryTimelineConnection>;
  /** Get tenant integrations */
  tenantIntegrations: Array<TenantIntegration>;
  /** Get all the tenants, optionally filtering */
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  /** Get all the text responses, optionally filtering */
  textResponses?: Maybe<TextResponseConnection>;
  /** Get threads, optionally filtering */
  threads?: Maybe<ThreadConnection>;
  /** Get TikTok oauth login url */
  tiktokOauthUrl?: Maybe<TiktokOauthUrlResult>;
  /** Get top stats */
  topStats?: Maybe<TopStatsResult>;
  /** Get an upcoming invoices */
  upcomingInvoice?: Maybe<Invoice>;
  /** Get all the users, optionally filtering */
  users?: Maybe<Array<Maybe<User>>>;
  /** Get all the video responses, optionally filtering */
  videoResponses?: Maybe<VideoResponseConnection>;
  /** Get a single video upload by ID */
  videoUpload?: Maybe<VideoUpload>;
  /** Get all the video uploads, optionally filtering */
  videoUploads?: Maybe<VideoUploadConnection>;
  /** Fetch current user */
  viewer?: Maybe<User>;
};


export type RootQueryTypeApiKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeChannelArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeChannelMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['ID']['input'];
  filter?: InputMaybe<ChannelMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeCheckEmailForInitiationInviteArgs = {
  email: Scalars['String']['input'];
  initiationId: Scalars['ID']['input'];
};


export type RootQueryTypeChoiceLogicsArgs = {
  choiceId: Scalars['ID']['input'];
};


export type RootQueryTypeCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeCompaniesForSelectArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeContactArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeContactsForSelectArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeFetchChannelUnreadCountArgs = {
  channelId: Scalars['ID']['input'];
};


export type RootQueryTypeFetchChannelsUnreadCountArgs = {
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type RootQueryTypeFetchContactFormByStoryIdArgs = {
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeFetchNotionPageArgs = {
  pageId: Scalars['String']['input'];
};


export type RootQueryTypeFetchStoriesUnreadCountArgs = {
  storyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type RootQueryTypeFetchStoryUnreadCountArgs = {
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeFileUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FileUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeImageUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeInitiationArgs = {
  id: Scalars['String']['input'];
};


export type RootQueryTypeInitiationInvitableMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  initiationId: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeInitiationInvitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  initiationId: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeInitiationItemArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeInitiationItemFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeInitiationItemsArgs = {
  initiationId: Scalars['ID']['input'];
};


export type RootQueryTypeInitiationLikeArgs = {
  initiationId: Scalars['ID']['input'];
};


export type RootQueryTypeInitiationMetaArgs = {
  id: Scalars['String']['input'];
};


export type RootQueryTypeInitiationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InitiationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeInstagramOauthUrlArgs = {
  input: GetInstagramOauthUrlInput;
};


export type RootQueryTypeInteractionsArgs = {
  initiationId: Scalars['ID']['input'];
};


export type RootQueryTypeMainGraphArgs = {
  filter: MainGraphFilter;
};


export type RootQueryTypeMemberInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: MemberFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeMyInitiationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeNodeArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypePageQuestionChoicesArgs = {
  questionId: Scalars['ID']['input'];
};


export type RootQueryTypePageQuestionsArgs = {
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeProductArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeSharedInitiationArgs = {
  id: Scalars['String']['input'];
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  isPreview: Scalars['Boolean']['input'];
};


export type RootQueryTypeShopifyProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootQueryTypeShoppableProductArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeStoriesStatsArgs = {
  filter: StoriesStatsFilter;
  page: Scalars['Int']['input'];
};


export type RootQueryTypeStoryFirstVideoArgs = {
  initiationId: Scalars['ID']['input'];
};


export type RootQueryTypeStoryLinkArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeStoryLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StoryLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeStoryNotificationReceiversArgs = {
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeStoryNotificationSettingArgs = {
  storyId: Scalars['ID']['input'];
};


export type RootQueryTypeStoryPageStatsArgs = {
  filter: StoryPageStatsFilter;
};


export type RootQueryTypeStoryPagesStatsArgs = {
  filter: StoryPagesStatsFilter;
};


export type RootQueryTypeStoryTimelineArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeStoryTimelinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StoryTimelineFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeTextResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TextResponseFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeThreadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeTiktokOauthUrlArgs = {
  input: GetTiktokOauthUrlInput;
};


export type RootQueryTypeTopStatsArgs = {
  filter: TopStatsFilter;
};


export type RootQueryTypeVideoResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VideoResponseFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeVideoUploadArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeVideoUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VideoUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Object returning after creating or updating a channel */
export type SaEventMutationResult = {
  __typename?: 'SaEventMutationResult';
  newToken?: Maybe<Scalars['String']['output']>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Attributes for creating or updating a product with shopify product */
export type SaveProductWithShopifyProductInput = {
  shopifyProductId: Scalars['String']['input'];
};

/** Attributes for creating or updating a product with shopify product */
export type SaveProductWithShopifyUrlInput = {
  url: Scalars['String']['input'];
};

export type SetBgImageUploadToEndScreenSettingInput = {
  imageUploadId: Scalars['ID']['input'];
};

export type SetFileUploadToContentInput = {
  fileUploadId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type SetVideoUploadToContentInput = {
  id: Scalars['ID']['input'];
  videoUploadId: Scalars['ID']['input'];
};

export type ShareDisabled = {
  __typename?: 'ShareDisabled';
  message?: Maybe<Scalars['String']['output']>;
};

export type SharedInitiationResult = {
  __typename?: 'SharedInitiationResult';
  initiation?: Maybe<InitiationResult>;
  sessionToken?: Maybe<Scalars['String']['output']>;
};

/** Shopify oauth info */
export type ShopifyOauthInfo = {
  __typename?: 'ShopifyOauthInfo';
  id: Scalars['ID']['output'];
  scope?: Maybe<Scalars['String']['output']>;
  shop?: Maybe<Scalars['String']['output']>;
};

/** Result of getting Shopify oauth url */
export type ShopifyOauthInfoResult = {
  __typename?: 'ShopifyOauthInfoResult';
  isInstalled: Scalars['Boolean']['output'];
  shopifyOauthInfo?: Maybe<ShopifyOauthInfo>;
};

/** Shopify Product */
export type ShopifyProduct = Node & {
  __typename?: 'ShopifyProduct';
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionHtml?: Maybe<Scalars['String']['output']>;
  featuredImage?: Maybe<ShopifyProductFeatureImage>;
  handle?: Maybe<Scalars['String']['output']>;
  hasOnlyDefaultVariant?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  onlineStoreUrl?: Maybe<Scalars['String']['output']>;
  productType: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['String']['output']>;
  shopifyId?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalInventory: Scalars['Int']['output'];
  totalVariants: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

export type ShopifyProductConnection = {
  __typename?: 'ShopifyProductConnection';
  edges?: Maybe<Array<Maybe<ShopifyProductEdge>>>;
  pageInfo: PageInfo;
};

export type ShopifyProductEdge = {
  __typename?: 'ShopifyProductEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ShopifyProduct>;
};

/** Shopify Product Feature Image */
export type ShopifyProductFeatureImage = {
  __typename?: 'ShopifyProductFeatureImage';
  altText?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** Shoppable Interaction */
export type ShoppableInteraction = Interaction & Node & {
  __typename?: 'ShoppableInteraction';
  /** Get the count of the shoppable products in the interaction. */
  count: Scalars['Int']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  shoppableInteractionId: Scalars['ID']['output'];
  shoppableProducts: Array<ShoppableProduct>;
  storyItem: InitiationItem;
  type: InteractionType;
};

/** Shoppable Product */
export type ShoppableProduct = Node & {
  __typename?: 'ShoppableProduct';
  ctaType: ShoppableProductCtaType;
  customCtaText?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  product: Product;
};

/** Shoppable Product CTA Type */
export enum ShoppableProductCtaType {
  /** Add to Cart */
  AddToCart = 'add_to_cart',
  /** Link */
  Link = 'link'
}

/** Object returning after creating or updating a shoppable product */
export type ShoppableProductMutationResult = {
  __typename?: 'ShoppableProductMutationResult';
  shoppableProduct?: Maybe<ShoppableProduct>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type ShortTextField = FormField & {
  __typename?: 'ShortTextField';
  contactProperty?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  /** Short Text Field */
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  type: FormFieldType;
};

/** Input fields for signing up as a user with email. */
export type SignupInput = {
  email: Scalars['String']['input'];
  locale: Locale;
};

export type StoriesStatsFilter = {
  period: AnalyticsPeriodType;
  target: AnalyticsTargetType;
  targetValue?: InputMaybe<Scalars['String']['input']>;
  /** Temporary, just guess timezone on client side */
  timezone: Scalars['String']['input'];
};

export type StoriesStatsItem = {
  __typename?: 'StoriesStatsItem';
  stats: BasicStoryStats;
  story: Initiation;
};

export type StoriesStatsResult = {
  __typename?: 'StoriesStatsResult';
  storiesStats: Array<StoriesStatsItem>;
};

/** Story Link object */
export type StoryLink = Node & {
  __typename?: 'StoryLink';
  customTitle?: Maybe<Scalars['String']['output']>;
  databaseId: Scalars['UUID']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  isLive: Scalars['Boolean']['output'];
  sid: Scalars['String']['output'];
  storyId: Scalars['String']['output'];
};

export type StoryLinkConnection = {
  __typename?: 'StoryLinkConnection';
  edges?: Maybe<Array<Maybe<StoryLinkEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StoryLinkEdge = {
  __typename?: 'StoryLinkEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<StoryLink>;
};

export type StoryLinkFilter = {
  customTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Object returning after creating or updating a initiation */
export type StoryLinkMutationResult = {
  __typename?: 'StoryLinkMutationResult';
  storyLink?: Maybe<StoryLink>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Available Story Locale */
export enum StoryLocale {
  /** German */
  De = 'de',
  /** English */
  En = 'en',
  /** Spanish */
  Es = 'es',
  /** French */
  Fr = 'fr',
  /** Italian */
  It = 'it',
  /** Japanese */
  Ja = 'ja',
  /** Korean */
  Ko = 'ko',
  /** Portuguese */
  Pt = 'pt',
  /** Russian */
  Ru = 'ru'
}

/** Story Notification Receiver */
export type StoryNotificationReceiver = Node & {
  __typename?: 'StoryNotificationReceiver';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  member: Member;
};

export type StoryNotificationReceiverResult = {
  __typename?: 'StoryNotificationReceiverResult';
  storyNotificationReceiver?: Maybe<StoryNotificationReceiver>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Story Notification Setting */
export type StoryNotificationSetting = Node & {
  __typename?: 'StoryNotificationSetting';
  collectLeadEmail: Scalars['Boolean']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  includeAnonymous: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  owner?: Maybe<User>;
  playEmail: Scalars['Boolean']['output'];
  reachEndEmail: Scalars['Boolean']['output'];
  responseEmail: Scalars['Boolean']['output'];
  story?: Maybe<Initiation>;
};

export type StoryNotificationSettingResult = {
  __typename?: 'StoryNotificationSettingResult';
  storyNotificationSetting?: Maybe<StoryNotificationSetting>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type StoryPageStats = {
  __typename?: 'StoryPageStats';
  stats: BasicStoryPageStats;
  storyPage: InitiationItem;
};

export type StoryPageStatsFilter = {
  period: AnalyticsPeriodType;
  storyPageId: Scalars['String']['input'];
  target: AnalyticsTargetType;
  targetValue?: InputMaybe<Scalars['String']['input']>;
  /** Temporary, just guess timezone on client side */
  timezone: Scalars['String']['input'];
};

export type StoryPageStatsResult = {
  __typename?: 'StoryPageStatsResult';
  storyPageStats: StoryPageStats;
};

export type StoryPagesStatsFilter = {
  period: AnalyticsPeriodType;
  target: AnalyticsTargetType;
  targetValue?: InputMaybe<Scalars['String']['input']>;
  /** Temporary, just guess timezone on client side */
  timezone: Scalars['String']['input'];
};

export type StoryPagesStatsResult = {
  __typename?: 'StoryPagesStatsResult';
  storyPagesStats: Array<StoryPageStats>;
};

/** Story Response */
export type StoryResponse = Node & {
  __typename?: 'StoryResponse';
  answerType?: Maybe<StoryResponseAnswerType>;
  endScreenId?: Maybe<Scalars['UUID']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  pageChoiceText?: Maybe<Scalars['String']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  pageQuestionText?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['UUID']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  stringValue?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  type: StoryResponseType;
  value?: Maybe<Scalars['Float']['output']>;
  videoUpload?: Maybe<VideoUpload>;
};

/** Story Response Answer Type */
export enum StoryResponseAnswerType {
  /** Register this type when a user submit an attachment. */
  Attachment = 'attachment',
  /** Register this type when a user submit an audio. */
  Audio = 'audio',
  /** Company Name */
  CompanyName = 'company_name',
  /** Custom Field for contact info */
  CustomField = 'custom_field',
  /** Email */
  Email = 'email',
  /** Register this type when a user submit a first name. */
  FirstName = 'first_name',
  /** Register this type when a user submit the full name. */
  FullName = 'full_name',
  /** Input */
  Input = 'input',
  /** Register this type when a user submit a last name. */
  LastName = 'last_name',
  /** Phone */
  Phone = 'phone',
  /** Register this type when a user submit a text. */
  Text = 'text',
  /** Register this type when a user submit a video. */
  Video = 'video'
}

/** Story Response Type */
export enum StoryResponseType {
  /** Send this event when a viewer click a choice */
  ChoiceClicked = 'choice_clicked',
  /** Send this event when a viewer submit the contact info */
  CollectInfo = 'collect_info',
  /** Send this event when a viewer submit a text response */
  CollectText = 'collect_text',
  /** Send this event when a viewer submit a video response */
  CollectVideo = 'collect_video',
  /** Send this event when a viewer click a cta */
  CtaClicked = 'cta_clicked',
  /** Send this event when a viewer clicked a product link */
  ProductLinkClicked = 'product_link_clicked',
  /** Send this event when a viewer reached the end of quiz or quiz result was settled */
  QuizResult = 'quiz_result',
  /** Send this event when a viewer reached the end ot the story */
  ReachEnd = 'reach_end',
  /** Send this event when a viewer submit an input */
  SubmitInput = 'submit_input'
}

/** Story Timeline */
export type StoryTimeline = Node & {
  __typename?: 'StoryTimeline';
  anonymousId?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  clickCount?: Maybe<Scalars['Int']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  contact?: Maybe<Contact>;
  duration?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isSpam?: Maybe<Scalars['Boolean']['output']>;
  lastEventAt?: Maybe<Scalars['DateTime']['output']>;
  markedAsSpamAt?: Maybe<Scalars['DateTime']['output']>;
  responses?: Maybe<Array<Maybe<StoryResponse>>>;
  story: Initiation;
  storySessionId?: Maybe<Scalars['String']['output']>;
  storySessionUserId?: Maybe<Scalars['String']['output']>;
  storySid?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type StoryTimelineConnection = {
  __typename?: 'StoryTimelineConnection';
  edges?: Maybe<Array<Maybe<StoryTimelineEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StoryTimelineEdge = {
  __typename?: 'StoryTimelineEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<StoryTimeline>;
};

export type StoryTimelineFilter = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  isSpam?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  storySid?: InputMaybe<Scalars['String']['input']>;
  withContact?: InputMaybe<Scalars['Boolean']['input']>;
  withInteractions?: InputMaybe<Scalars['Boolean']['input']>;
  withResponses?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Object returning after creating or updating a channel */
export type StoryTimelineResult = {
  __typename?: 'StoryTimelineResult';
  storyTimeline?: Maybe<StoryTimeline>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Story type */
export enum StoryType {
  /** Form-type stories */
  Form = 'form',
  /** Quiz-type stories */
  Quiz = 'quiz',
  /** Reel-type stories */
  Reel = 'reel',
  /** Story-type stories. */
  Story = 'story'
}

export type StoryUnreadCount = {
  __typename?: 'StoryUnreadCount';
  storyDatabaseId: Scalars['UUID']['output'];
  storyId: Scalars['ID']['output'];
  storySid: Scalars['String']['output'];
  unreadCount: Scalars['Int']['output'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  url: Scalars['String']['output'];
};

export type StripeCustomerPortalSession = {
  __typename?: 'StripeCustomerPortalSession';
  url: Scalars['String']['output'];
};

/** Subscription Status. Check details at https://stripe.com/docs/billing/subscriptions/overview#subscription-objects */
export enum SubscriptionStatus {
  /** The subscription is in good standing and the most recent payment is successful. It’s safe to provision your product for your customer. */
  Active = 'active',
  /** The subscription has been canceled. During cancellation, automatic collection for all unpaid invoices is disabled (auto_advance=false). This is a terminal state that can’t be updated. */
  Canceled = 'canceled',
  /** A successful payment needs to be made within 23 hours to activate the subscription. Or the payment requires action, like customer authentication. Read more about payments that require action. Subscriptions can also be incomplete if there’s a pending payment. In that case, the invoice status would be open_payment_pending and the PaymentIntent status would be processing. */
  Incomplete = 'incomplete',
  /** A successful payment needs to be made within 23 hours to activate the subscription. Or the payment requires action, like customer authentication. Read more about payments that require action. Subscriptions can also be incomplete if there’s a pending payment. In that case, the invoice status would be open_payment_pending and the PaymentIntent status would be processing. */
  IncompleteExpired = 'incomplete_expired',
  /** Payment on the latest finalized invoice either failed or wasn’t attempted. The subscription continues to create invoices. Your subscription settings determine the subscription’s next state. If the invoice is still unpaid after all Smart Retries have been attempted, you can configure the subscription to move to canceled, unpaid, or leave it as past_due. To move the subscription to active, pay the most recent invoice before its due date. */
  PastDue = 'past_due',
  /** The subscription is currently in a trial period and it’s safe to provision your product for your customer. The subscription transitions automatically to active when the first payment is made. */
  Trialing = 'trialing',
  /** The latest invoice hasn’t been paid but the subscription remains in place. The latest invoice remains open and invoices continue to be generated but payments aren’t attempted. You should revoke access to your product when the subscription is unpaid since payments were already attempted and retried when it was past_due. To move the subscription to active, pay the most recent invoice before its due date. */
  Unpaid = 'unpaid'
}

/** Tenant */
export type Tenant = Node & {
  __typename?: 'Tenant';
  canUseCoupon?: Maybe<Scalars['Boolean']['output']>;
  canUseTrial?: Maybe<Scalars['Boolean']['output']>;
  canUseVideoPreview?: Maybe<Scalars['Boolean']['output']>;
  commerceActivated: Scalars['Boolean']['output'];
  databaseId: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  exceedMaxLifetimePlaysAt?: Maybe<Scalars['DateTime']['output']>;
  exceedMaxMonthlyImpressionsAt?: Maybe<Scalars['DateTime']['output']>;
  exceedMaxMonthlyPlaysAt?: Maybe<Scalars['DateTime']['output']>;
  exceededMaxStorage?: Maybe<Scalars['Boolean']['output']>;
  features: Array<PlanFeature>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPersonal?: Maybe<Scalars['Boolean']['output']>;
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  lifetimePlays?: Maybe<Scalars['Int']['output']>;
  lifetimePlaysCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  maxLifetimePlays?: Maybe<Scalars['Int']['output']>;
  maxMonthlyImpressions?: Maybe<Scalars['Int']['output']>;
  maxMonthlyPlays?: Maybe<Scalars['Int']['output']>;
  maxSeats?: Maybe<Scalars['Int']['output']>;
  maxStorage?: Maybe<Scalars['Int']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  monthlyImpressions?: Maybe<Scalars['Int']['output']>;
  monthlyImpressionsCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  monthlyPlays?: Maybe<Scalars['Int']['output']>;
  monthlyPlaysCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  slug?: Maybe<Scalars['String']['output']>;
  storageUsage?: Maybe<Scalars['Float']['output']>;
  storyCount?: Maybe<Scalars['Int']['output']>;
  userSetMaxMonthlyPlays?: Maybe<Scalars['Int']['output']>;
};

/** Tenant Instagram Info Object */
export type TenantInstagramInfo = {
  __typename?: 'TenantInstagramInfo';
  accessTokenExpiresAt?: Maybe<Scalars['Date']['output']>;
};

/** Tenant Integration Object */
export type TenantIntegration = {
  __typename?: 'TenantIntegration';
  canConnect: Scalars['Boolean']['output'];
  connectedAccountName?: Maybe<Scalars['String']['output']>;
  connectedAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  guideUrl?: Maybe<Scalars['String']['output']>;
  isConnected: Scalars['Boolean']['output'];
  linkUrl?: Maybe<Scalars['String']['output']>;
  type: TenantIntegrationType;
};

/** Tenant Integration Type */
export enum TenantIntegrationType {
  /** Instagram Integration */
  Instagram = 'instagram',
  /** Shopify Integration */
  Shopify = 'shopify',
  /** TikTok Integration */
  Tiktok = 'tiktok'
}

/** Return when tenant is updated */
export type TenantResult = {
  __typename?: 'TenantResult';
  tenant?: Maybe<Tenant>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Tenant TikTok Info Object */
export type TenantTiktokInfo = {
  __typename?: 'TenantTiktokInfo';
  accessTokenExpiresAt?: Maybe<Scalars['Date']['output']>;
  refreshTokenExpiresAt?: Maybe<Scalars['Date']['output']>;
};

/** Text Response */
export type TextResponse = Node & {
  __typename?: 'TextResponse';
  contact?: Maybe<Contact>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Scalars['String']['output'];
  question: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type TextResponseConnection = {
  __typename?: 'TextResponseConnection';
  edges?: Maybe<Array<Maybe<TextResponseEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TextResponseEdge = {
  __typename?: 'TextResponseEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TextResponse>;
};

/** Filtering text responses. */
export type TextResponseFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
  storyItemId?: InputMaybe<Scalars['ID']['input']>;
};

/** Thread object */
export type Thread = Node & {
  __typename?: 'Thread';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  initiation?: Maybe<Initiation>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ThreadConnection = {
  __typename?: 'ThreadConnection';
  edges?: Maybe<Array<Maybe<ThreadEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ThreadEdge = {
  __typename?: 'ThreadEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Thread>;
};

/** Object returning after creating or updating a channel */
export type ThreadResult = {
  __typename?: 'ThreadResult';
  thread?: Maybe<Thread>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Result of verifying TikTok oauth callback */
export type TiktokAccessTokenResult = {
  __typename?: 'TiktokAccessTokenResult';
  tenantTiktokInfo?: Maybe<TenantTiktokInfo>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Result of getting oauth login url for TikTok */
export type TiktokOauthUrlResult = {
  __typename?: 'TiktokOauthUrlResult';
  url?: Maybe<Scalars['String']['output']>;
};

/** Use this stat for integer type metric */
export type TopStatInt = {
  __typename?: 'TopStatInt';
  change?: Maybe<Scalars['Int']['output']>;
  metric?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type TopStats = {
  __typename?: 'TopStats';
  avgDuration: TopStatInt;
  impressions: TopStatInt;
  pageviews: TopStatInt;
  plays: TopStatInt;
};

export type TopStatsFilter = {
  period: AnalyticsPeriodType;
  target: AnalyticsTargetType;
  targetValue?: InputMaybe<Scalars['String']['input']>;
  /** Temporary, just guess timezone on client side */
  timezone: Scalars['String']['input'];
};

export type TopStatsResult = {
  __typename?: 'TopStatsResult';
  interval: AnalyticsIntervalType;
  topStats: TopStats;
};

export type UnknownError = {
  __typename?: 'UnknownError';
  message?: Maybe<Scalars['String']['output']>;
};

/** Input fields for unlike an initiation. */
export type UnlikeInitiationInput = {
  initiationId: Scalars['ID']['input'];
};

export type UpdateApiKeyInput = {
  name: Scalars['String']['input'];
};

export type UpdateApiKeyResult = {
  __typename?: 'UpdateApiKeyResult';
  apiKey?: Maybe<ApiKey>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

/** Input fields for updating notification setting */
export type UpdateBrandSettingInput = {
  primaryColor?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating a channel. */
export type UpdateChannelInput = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateChoiceActionInput = {
  nextPageId?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ChoiceActionResultType>;
  strValue?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ChoiceActionType>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateChoiceConditionalInput = {
  choiceId?: InputMaybe<Scalars['ID']['input']>;
  comparison?: InputMaybe<ChoiceConditionalComparisonType>;
  questionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateChoiceLogicInput = {
  actions?: InputMaybe<Array<ChoiceActionInput>>;
  conditionals?: InputMaybe<Array<ChoiceConditionalInput>>;
  operator: LogicOperatorType;
};

/** Attributes for updating a company */
export type UpdateCompanyInput = {
  icon?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for updating a contact */
export type UpdateConsentInput = {
  isRequired: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

/** handle reorder, add, remove, update. Do the reordering on the client side. */
export type UpdateContactFormFieldsInput = {
  formFields: Array<FormFieldInput>;
};

/** Input fields for updating a contact form. */
export type UpdateContactFormInput = {
  canSkip: Scalars['Boolean']['input'];
  consents: Array<ContactFormConsentInput>;
  formFields: Array<FormFieldInput>;
  isActive: Scalars['Boolean']['input'];
  prompt: Scalars['String']['input'];
  showConsent: Scalars['Boolean']['input'];
};

/** Attributes for updating a contact */
export type UpdateContactInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating a doc content. */
export type UpdateContentInput = {
  body?: InputMaybe<Scalars['Json']['input']>;
  embedLink?: InputMaybe<Scalars['String']['input']>;
  fileUploadId?: InputMaybe<Scalars['ID']['input']>;
  groupType?: InputMaybe<ContentGroupType>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  oembedResult?: InputMaybe<Scalars['Json']['input']>;
  plainDescription?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentType>;
  videoContain?: InputMaybe<Scalars['Boolean']['input']>;
  videoUploadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Input fields for updating a doc content. */
export type UpdateContentNarrationInput = {
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NarrationType>;
};

/** Input fields for updating a widget setting. */
export type UpdateEndScreenSettingInput = {
  bgGradientAngle: Scalars['Int']['input'];
  bgGradientType: GradientType;
  bgType: BackgroundType;
  blurEffect: Scalars['Int']['input'];
  colors: Array<ColorInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  ctaBgColor?: InputMaybe<Scalars['String']['input']>;
  ctaText?: InputMaybe<Scalars['String']['input']>;
  ctaTextColor?: InputMaybe<Scalars['String']['input']>;
  ctaUrl?: InputMaybe<Scalars['String']['input']>;
  showCta: Scalars['Boolean']['input'];
  textColor: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating file_upload. */
export type UpdateFileUploadInput = {
  alternativeText: Scalars['String']['input'];
};

/** Input fields for updating image_upload. */
export type UpdateImageUploadInput = {
  alternativeText: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Input fields for updating a thread. */
export type UpdateInitiationInput = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Input fields for updating story interactiveness */
export type UpdateInitiationInteractivenessInput = {
  id: Scalars['ID']['input'];
  isInteractive: Scalars['Boolean']['input'];
};

/** Input fields for updating an initiation invite. */
export type UpdateInitiationInviteInput = {
  id: Scalars['ID']['input'];
  role: InitiationMemberRole;
};

/** Input fields for updating an initiation item. */
export type UpdateInitiationItemInput = {
  bgGradientAngle?: InputMaybe<Scalars['Int']['input']>;
  bgGradientType?: InputMaybe<GradientType>;
  bgType?: InputMaybe<BackgroundType>;
  blurEffect?: InputMaybe<Scalars['Int']['input']>;
  collectContact?: InputMaybe<Scalars['Boolean']['input']>;
  colors?: InputMaybe<Array<ColorInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  interactionType?: InputMaybe<InteractionType>;
  showCompanyName?: InputMaybe<Scalars['Boolean']['input']>;
  showCompanySymbol?: InputMaybe<Scalars['Boolean']['input']>;
  showContact?: InputMaybe<Scalars['Boolean']['input']>;
  showContactAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  showContactJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  showContactName?: InputMaybe<Scalars['Boolean']['input']>;
  teleprompterFontColor?: InputMaybe<Scalars['String']['input']>;
  teleprompterFontSize?: InputMaybe<Scalars['Int']['input']>;
  teleprompterScript?: InputMaybe<Scalars['String']['input']>;
  teleprompterSpeed?: InputMaybe<Scalars['Int']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InitiationItemType>;
  useTeleprompter?: InputMaybe<Scalars['Boolean']['input']>;
  useTextFallback?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input fields for updating the story setting. */
export type UpdateInitiationSettingInput = {
  canChangeMediaSpeed?: InputMaybe<Scalars['Boolean']['input']>;
  canShare?: InputMaybe<Scalars['Boolean']['input']>;
  choiceLetterType?: InputMaybe<ChoiceLetterType>;
  id: Scalars['ID']['input'];
  linkPolicy?: InputMaybe<ContentAccessPolicy>;
  locale?: InputMaybe<StoryLocale>;
  orientation?: InputMaybe<PlayerOrientationType>;
  showBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showChoiceLetters?: InputMaybe<Scalars['Boolean']['input']>;
  showPages?: InputMaybe<Scalars['Boolean']['input']>;
  tenantMemberPolicy?: InputMaybe<ContentAccessPolicy>;
  threadCommentable?: InputMaybe<Scalars['Boolean']['input']>;
  threadVisible?: InputMaybe<Scalars['Boolean']['input']>;
  widgetType?: InputMaybe<WidgetType>;
};

/** Input fields for updating story interactiveness */
export type UpdateInitiationWidgetLiveInput = {
  id: Scalars['ID']['input'];
  widgetLive: Scalars['Boolean']['input'];
};

export type UpdateMemberAsAdminInput = {
  id: Scalars['ID']['input'];
  roleName: Scalars['String']['input'];
};

export type UpdateMemberInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Input fields for updating user */
export type UpdateMemberInvitationInput = {
  id: Scalars['ID']['input'];
  roleName: Scalars['String']['input'];
};

/** Input fields for updating a doc content. */
export type UpdateNarrationChapterInput = {
  id: Scalars['ID']['input'];
  slidePageNumber?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating notification setting */
export type UpdateNotificationSettingInput = {
  emailNews?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

/** Input fields for updating a page choice. */
export type UpdatePageChoiceInput = {
  hasNext?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  middleInteraction?: InputMaybe<ChoiceMiddleInteractionInput>;
  nextPageId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NextInteractionType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating a thread. */
export type UpdatePageQuestionInput = {
  id: Scalars['ID']['input'];
  showTitle?: InputMaybe<Scalars['Boolean']['input']>;
  timing?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleColor?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating password as logged in user. */
export type UpdatePasswordInput = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Attributes for updating a product */
export type UpdateProductInput = {
  currencyCode: Scalars['String']['input'];
  descriptionHtml?: InputMaybe<Scalars['String']['input']>;
  imageUploadId: Scalars['String']['input'];
  onlineStoreUrl: Scalars['String']['input'];
  originalPrice?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Type of source */
  sourceType: ProductSourceType;
  title: Scalars['String']['input'];
};

/** Attributes for updating a shoppable product and product. */
export type UpdateShoppableProductAndProductInput = {
  ctaType: ShoppableProductCtaType;
  currencyCode: Scalars['String']['input'];
  customCtaText?: InputMaybe<Scalars['String']['input']>;
  imageUploadId?: InputMaybe<Scalars['String']['input']>;
  onlineStoreUrl: Scalars['String']['input'];
  originalPrice?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Type of source */
  sourceType: ProductSourceType;
  title: Scalars['String']['input'];
};

/** Attributes for updating a shoppable product. */
export type UpdateShoppableProductInput = {
  ctaType?: InputMaybe<ShoppableProductCtaType>;
  customCtaText?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating a thread. */
export type UpdateStoryLinkInput = {
  customTitle: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isLive: Scalars['Boolean']['input'];
};

/** Attributes for updating a story notification setting */
export type UpdateStoryNotificationSettingInput = {
  collectLeadEmail?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  includeAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isMemberDefault: Scalars['Boolean']['input'];
  playEmail?: InputMaybe<Scalars['Boolean']['input']>;
  reachEndEmail?: InputMaybe<Scalars['Boolean']['input']>;
  responseEmail?: InputMaybe<Scalars['Boolean']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for updating a story timeline */
export type UpdateStoryTimelineInput = {
  anonymousId?: InputMaybe<Scalars['String']['input']>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  clickCount?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  isSpam?: InputMaybe<Scalars['Boolean']['input']>;
  lastEventAt?: InputMaybe<Scalars['DateTime']['input']>;
  markedAsSpamAt?: InputMaybe<Scalars['DateTime']['input']>;
  storySessionId?: InputMaybe<Scalars['String']['input']>;
  storySessionUserId?: InputMaybe<Scalars['String']['input']>;
  storySid?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating tenant basic info */
export type UpdateTenantInput = {
  commerceActivated?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for updating a thread. */
export type UpdateThreadInput = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Input fields for updating user */
export type UpdateUserInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
};

/** Input fields for creating video upload after upload on client side. Temporary, use for media recorder, too. */
export type UpdateVideoUploadInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

/** Input fields for updating a widget setting. */
export type UpdateWidgetSettingInput = {
  allowHiding?: InputMaybe<Scalars['Boolean']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  bottomMargin?: InputMaybe<Scalars['Int']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  displayOnDesktop?: InputMaybe<Scalars['Boolean']['input']>;
  displayOnMobile?: InputMaybe<Scalars['Boolean']['input']>;
  initialPlaybackMode?: InputMaybe<InitialPlaybackMode>;
  leftAligned?: InputMaybe<Scalars['Boolean']['input']>;
  shape?: InputMaybe<WidgetShape>;
  showBorder?: InputMaybe<Scalars['Boolean']['input']>;
  showTextBubble?: InputMaybe<Scalars['Boolean']['input']>;
  sideMargin?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<WidgetSize>;
  textBubbleBgColor?: InputMaybe<Scalars['String']['input']>;
  textBubbleMessage?: InputMaybe<Scalars['String']['input']>;
  textBubbleTextColor?: InputMaybe<Scalars['String']['input']>;
  useFullScreen?: InputMaybe<Scalars['Boolean']['input']>;
  useVideoPreview?: InputMaybe<Scalars['Boolean']['input']>;
  widgetAlignment?: InputMaybe<WidgetAlignment>;
  widgetBorderRadius?: InputMaybe<Scalars['Int']['input']>;
  widgetBorderThickness?: InputMaybe<Scalars['Int']['input']>;
  widgetIsResponsive?: InputMaybe<Scalars['Boolean']['input']>;
  widgetItemGap?: InputMaybe<Scalars['Int']['input']>;
  widgetItemHeight?: InputMaybe<Scalars['Int']['input']>;
  widgetItemWidth?: InputMaybe<Scalars['Int']['input']>;
  widgetItemsPerRow?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadAvatarInput = {
  file: Scalars['Upload']['input'];
};

export type UploadBrandLogoForDarkBgInput = {
  logoForDarkBgFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type UploadBrandLogoInput = {
  logoFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type UploadBrandSymbolForDarkBgInput = {
  symbolForDarkBgFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type UploadBrandSymbolInput = {
  symbolFile?: InputMaybe<Scalars['Upload']['input']>;
};

/** Input fields for create a channel. */
export type UploadChunkInput = {
  chunk: Scalars['Upload']['input'];
  sequence: Scalars['Int']['input'];
  tempId: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
  uploadParts: Array<InputMaybe<UploadPartInput>>;
  uploadPath: Scalars['String']['input'];
};

/** Attributes for uploading a company icon */
export type UploadCompanyIconInput = {
  icon?: InputMaybe<Scalars['Upload']['input']>;
};

export type UploadInitiationThumbnailInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['ID']['input'];
};

export type UploadMemberAvatarInput = {
  file: Scalars['Upload']['input'];
};

/** upload part returned after uploaded chunk. */
export type UploadPart = {
  __typename?: 'UploadPart';
  etag: Scalars['String']['output'];
  sequence: Scalars['Int']['output'];
};

export type UploadPartInput = {
  etag: Scalars['String']['input'];
  sequence: Scalars['Int']['input'];
};

/** State of multi part upload */
export type UploadState = {
  __typename?: 'UploadState';
  tempId: Scalars['String']['output'];
  uploadId: Scalars['String']['output'];
  uploadParts: Array<Maybe<UploadPart>>;
  uploadPath: Scalars['String']['output'];
  uploaded: Scalars['Boolean']['output'];
};

/** Status of file upload */
export enum UploadStatus {
  /** Before generating gif */
  BeforeGenGif = 'before_gen_gif',
  /** generating thumb, probably better gen thumb after transcoding. */
  BeforeGenThumb = 'before_gen_thumb',
  /** Uploaded and waiting for start transcoding */
  BeforeTranscode = 'before_transcode',
  /** Requested upload url and waiting */
  BeforeUpload = 'before_upload',
  /** Transcode completed */
  Completed = 'completed',
  /** Gen gif failed, remove in the future */
  GenGifFailed = 'gen_gif_failed',
  /** Gen thumb failed, this is not a big issue and better remove in the future */
  GenThumbFailed = 'gen_thumb_failed',
  /** Uploaded a file, but for some reason, transcoding failed. This is critical. */
  TranscodeFailed = 'transcode_failed',
  /** Created a request but didn't upload a file. */
  UploadAborted = 'upload_aborted',
  /** Couldn't complete upload for some reason */
  UploadFailed = 'upload_failed',
  /** Uploading a file */
  Uploading = 'uploading'
}

/** Input fields for uploading tenant icon */
export type UploadTenantIconInput = {
  file: Scalars['Upload']['input'];
};

/** Input fields for uploading a video. */
export type UploadVideoInput = {
  duration: Scalars['Float']['input'];
  file: Scalars['Upload']['input'];
  initiationItemId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
};

/** User */
export type User = Node & {
  __typename?: 'User';
  activeTenant?: Maybe<Tenant>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** User's database id, mainly for tracking. */
  databaseId: Scalars['UUID']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  locale: Locale;
  member?: Maybe<Member>;
  notificationSetting?: Maybe<NotificationSetting>;
  onboardingStatus: OnboardingStatus;
  role: Scalars['String']['output'];
  tenantCount: Scalars['Int']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

/** User input error */
export type UserError = {
  __typename?: 'UserError';
  field: Scalars['String']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  message: Scalars['String']['output'];
};

/** Input fields for verifying email. */
export type VerifyEmailInput = {
  code: Scalars['String']['input'];
};

/** Input fields for verifying HMAC value on installing Sharelo's Shopify App. */
export type VerifyShopifyInstallationRequestInput = {
  hmac: Scalars['String']['input'];
  message: Scalars['String']['input'];
  shop: Scalars['String']['input'];
  timestamp: Scalars['String']['input'];
};

/** Result of verifying installation request */
export type VerifyShopifyInstallationRequestResult = {
  __typename?: 'VerifyShopifyInstallationRequestResult';
  nonce?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
};

export type VideoField = FormField & {
  __typename?: 'VideoField';
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  contactProperty?: Maybe<Scalars['String']['output']>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  /** Video Field */
  id: Scalars['String']['output'];
  isCustomField: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  type: FormFieldType;
};

/** Video Processing Request */
export type VideoProcessingRequest = Node & {
  __typename?: 'VideoProcessingRequest';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  status?: Maybe<VideoProcessingStatus>;
};

/** Video Processing Status */
export enum VideoProcessingStatus {
  /** Video processing completed */
  Completed = 'completed',
  /** Video processing failed */
  Failed = 'failed',
  /** Video is processing */
  Processing = 'processing',
  /** Video is queued for processing */
  Queued = 'queued'
}

/** Text Response */
export type VideoResponse = Node & {
  __typename?: 'VideoResponse';
  contact?: Maybe<Contact>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  storyResponse?: Maybe<StoryResponse>;
  videoUpload?: Maybe<VideoUpload>;
};

export type VideoResponseConnection = {
  __typename?: 'VideoResponseConnection';
  edges?: Maybe<Array<Maybe<VideoResponseEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VideoResponseEdge = {
  __typename?: 'VideoResponseEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<VideoResponse>;
};

/** Filtering video responses. */
export type VideoResponseFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
  storyId?: InputMaybe<Scalars['ID']['input']>;
  storyItemId?: InputMaybe<Scalars['ID']['input']>;
};

/** Video Upload Info */
export type VideoUpload = Node & {
  __typename?: 'VideoUpload';
  alternativeText?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  currentGifRequest?: Maybe<VideoProcessingRequest>;
  duration?: Maybe<Scalars['Float']['output']>;
  gifUrl?: Maybe<Scalars['String']['output']>;
  gifWithIconUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  isRecorded?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  rawUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  streamUrl?: Maybe<Scalars['String']['output']>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  transcodedUrl?: Maybe<Scalars['String']['output']>;
};

export type VideoUploadConnection = {
  __typename?: 'VideoUploadConnection';
  edges?: Maybe<Array<Maybe<VideoUploadEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VideoUploadEdge = {
  __typename?: 'VideoUploadEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<VideoUpload>;
};

export type VideoUploadFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<VideoUploadSourceType>;
};

/** Video Upload Request Info */
export type VideoUploadRequest = Node & {
  __typename?: 'VideoUploadRequest';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  status?: Maybe<UploadStatus>;
  taskToken?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
};

/** returnd one-time tus upload url from cloudflare stream */
export type VideoUploadRequestResult = {
  __typename?: 'VideoUploadRequestResult';
  userErrors?: Maybe<Array<Maybe<UserError>>>;
  videoUploadRequest?: Maybe<VideoUploadRequest>;
};

/** return this after uploading a video. */
export type VideoUploadResult = {
  __typename?: 'VideoUploadResult';
  content?: Maybe<Content>;
  userErrors?: Maybe<Array<Maybe<UserError>>>;
  videoUpload?: Maybe<VideoUpload>;
};

/** Video Upload Source Types */
export enum VideoUploadSourceType {
  /** Collected */
  Collected = 'collected',
  /** Instagram */
  Instagram = 'instagram',
  /** Uploaded */
  Uploaded = 'uploaded'
}

/** Return when auth token is not required */
export type ViewerResult = {
  __typename?: 'ViewerResult';
  userErrors?: Maybe<Array<Maybe<UserError>>>;
  viewer?: Maybe<User>;
};

/** Widget Alignment */
export enum WidgetAlignment {
  /** Center */
  Center = 'center',
  /** Left */
  Left = 'left',
  /** Right */
  Right = 'right'
}

/** Widget Setting */
export type WidgetSetting = Node & {
  __typename?: 'WidgetSetting';
  allowHiding: Scalars['Boolean']['output'];
  borderColor?: Maybe<Scalars['String']['output']>;
  bottomMargin?: Maybe<Scalars['Int']['output']>;
  caption: Scalars['String']['output'];
  displayOnDesktop: Scalars['Boolean']['output'];
  displayOnMobile: Scalars['Boolean']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  initialPlaybackMode: InitialPlaybackMode;
  leftAligned: Scalars['Boolean']['output'];
  shape: WidgetShape;
  showBorder: Scalars['Boolean']['output'];
  showTextBubble: Scalars['Boolean']['output'];
  sideMargin?: Maybe<Scalars['Int']['output']>;
  size: WidgetSize;
  textBubbleBgColor?: Maybe<Scalars['String']['output']>;
  textBubbleMessage?: Maybe<Scalars['String']['output']>;
  textBubbleTextColor?: Maybe<Scalars['String']['output']>;
  useFullScreen: Scalars['Boolean']['output'];
  useVideoPreview: Scalars['Boolean']['output'];
  widgetAlignment: WidgetAlignment;
  widgetBorderRadius: Scalars['Int']['output'];
  widgetBorderThickness: Scalars['Int']['output'];
  widgetIsResponsive: Scalars['Boolean']['output'];
  widgetItemGap: Scalars['Int']['output'];
  widgetItemHeight: Scalars['Int']['output'];
  widgetItemWidth: Scalars['Int']['output'];
  widgetItemsPerRow: Scalars['Int']['output'];
};

/** Object returning after creating or updating an widget_setting */
export type WidgetSettingResult = {
  __typename?: 'WidgetSettingResult';
  userErrors?: Maybe<Array<UserError>>;
  widgetSetting?: Maybe<WidgetSetting>;
};

/** Widget Shape */
export enum WidgetShape {
  /** Circle */
  Circle = 'circle',
  /** Rectangle */
  Rectangle = 'rectangle'
}

/** Widget Size */
export enum WidgetSize {
  /** Large */
  Large = 'large',
  /** Medium */
  Medium = 'medium',
  /** Small */
  Small = 'small'
}

/** Widget Type */
export enum WidgetType {
  /** Carousel */
  Carousel = 'carousel',
  /** Grid */
  Grid = 'grid',
  /** Popup */
  Popup = 'popup',
  /** Stories */
  Stories = 'stories'
}
